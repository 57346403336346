
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {},
  choice: {
    margin: 10,
  },
  avatar: {
    height: "100px",
    border: "4px #555555 solid",
    borderRadius: 10,
    marginRight: "5px",
    filter: "grayscale(100%)",
    objectFit: "none",
    '&.selected': {
      border: "4px #F00 solid",
      filter: "grayscale(0)",
    },
  },
});

export default function PlayerCheckbox(props) {
  const { playerIndex, playerName, selected, handleChange } = props;
  const classes = useStyles();
  return (
    <FormControlLabel key={'form-control-label-' + playerIndex}
      control={
          <Checkbox
            style={{ display:"none" }}
            checked={selected}
            onChange={handleChange}
            value={playerIndex}
            key={'player-checkbox-' + playerIndex} />
      }
      label={
        <div className={classes.choice}>
            <img 
              src={'./assets/avatars/'+ playerName +'.gif'}
              className={classes.avatar + (selected ? " selected": "")} width="100px" height="auto"
             />
            <br />Player {playerIndex+1}
        </div>
      }
    />
  )
}