const PLAYERS = [
    "Balls",
    "Biggie Sized",
    "Boner",
    "Flamming Anus",
    "Fuck Up",
    "Hairy Tit",
    "My Ass Is Soo Big",
    "Pussy is Delicious",
    "Snatch",
    "Stinky Pinky",
    "Suck A Booty Soo Good",
    "Sweaty Vagina"
].map(value => ({ value, sort: Math.random() }))
 .sort((a, b) => a.sort - b.sort)
 .map(({ value }) => value);

console.log(PLAYERS);
export default PLAYERS;