import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import PlayerCheckbox from './PlayerCheckbox';
import PLAYERS from './PlayerData';


export default function Players(props) {

  const MIN_PLAYERS = 2;
  const MAX_PLAYERS = PLAYERS.length;
  const [selectedPlayers, setSelectedPlayers] = React.useState(new Array(MAX_PLAYERS).fill(false));
  const [playerCount, setPlayerCount] = React.useState(0);
  const [canContinue, setCanContinue] = React.useState(false);
  const [direction, setDirection] = React.useState();
  const audio = new Audio();

  React.useEffect(() => {
    if (direction) {
      const players = [];
      selectedPlayers.map((checked, i) => {
        if (checked === true) {
          players.push(i);
        }
      });
      props.callback(direction, players);
    }
  }, [direction]);

  const handleChange = (event) => {
    selectedPlayers[event.target.value] = event.target.checked;
    setSelectedPlayers(selectedPlayers);
    setPlayerCount(playerCount + (event.target.checked ? 1 : -1));
    if (event.target.checked) {
      const playerName = PLAYERS[event.target.value];
      audio.pause();
      audio.src = "./assets/audio/game-piece-sounds-with-effects/" + playerName + ".mp3";
      audio.play();
    }
  };

  const doCallback = (event) => {
    props.callback(true);
  }

  return (
    <Grid container alignItems="center" justifyContent="space-evenly" spacing={2}>
        <Grid item xs={12}>
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Select Players
          </Typography>
        </Grid>
        <Grid item xs={11} align="center">
          {
            PLAYERS.map((name, index) => {
              return <PlayerCheckbox
                  playerIndex={index}
                  playerName={name}
                  selected={selectedPlayers[index] === true}
                  handleChange={handleChange}
                  key={"player-checkbox-component-"+index}
                />
            })
          }
        </Grid>
        {playerCount >= MIN_PLAYERS ? (
          <Grid item xs={12} sm={6} align="center">
            <Button onClick={(event) => { setDirection("next") }} variant="contained" color="primary">Select Categories &raquo;</Button>
          </Grid>
        ) : null}
    </Grid>
  );
}
