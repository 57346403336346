import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import CATEGORIES from './Categories';


const useStyles = makeStyles({
  root: {},
  categoryChoice: {
    height: 60,
    width: 260,
    border: "3px #AAA solid",
    borderRadius: 10,
    color: 'black',
    fontSize: 18,
    textAlign: 'center',
    verticalAlign: 'center',
    padding: '10px 0',
    lineHeight: '200%',
    '&:hover': {
      borderColor: "#999",
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    },
    '&.selected': {
      borderColor: "#F00",
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
    },
    '&.levelLite': {
      background: 'linear-gradient(0deg, #edd924 1%, #ede624 150%)',
      '&:hover': {
        background: 'linear-gradient(-20deg, #edd924 1%, #ede624 100%)',
      }
    },
    '&.levelMedium': {
      background: 'linear-gradient(-10deg, #f07000 1%, #ede624 100%)',
      '&:hover': {
        background: 'linear-gradient(-20deg, #f07000 1%, #ede624 100%)',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .4)',
      }
    },
    '&.levelHeavier': {
      background: 'linear-gradient(0deg, #de3907 1%, #edad24 150%)',
      '&:hover': {
        background: 'linear-gradient(5deg, #de3907 1%, #edad24 150%)',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .4)',
      }
    }
  },
});

export default function SelectCategories(props) {
  const [canContinue, setCanContinue] = React.useState(false);
  const [categoryCount, setCategoryCount] = React.useState(0);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [direction, setDirection] = React.useState();
  const classes = useStyles();

  React.useEffect(() => {
    if (direction) {
      const categories = [];
      selectedCategories.map((checked, i) => {
        if (checked === true) {
          categories.push(i);
        }
      });
      props.callback(direction, categories);
    }
  }, [direction]);

  React.useEffect(() => {
    if (categoryCount > 1) {
      setCanContinue(true);
    }
  }, [categoryCount]);

  const handleChange = (event) => {
    selectedCategories[event.target.value] = event.target.checked;
    setCategoryCount(categoryCount + (event.target.checked ? 1 : -1));
  }

  const isSelected = (i) => {
    return selectedCategories[i];
  }

  let node = [];
  for(var i=0; i<CATEGORIES.length; i++){
      node.push(i);
  }

  return (
    <Container align="center">
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Select Categories
        </Typography>

        <Box my={4}>
          <Grid container spacing={3}>
            {CATEGORIES.map((category, i) => {
              return (
                <Grid item xs={12} sm={6} md={4} align="center"  key={'category-grid-' + i}>

                  <FormControlLabel key={'category-form-control-' + i}
                    control={
                        <Checkbox style={{display: "none"}} onChange={handleChange} value={i} key={'category-checkbox-' + i} />
                    }
                    label={
                      <div className={classes.categoryChoice + " level"+category.level + (isSelected(i) ? " selected" : "")}>
                          {category.title}
                      </div>
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
        {canContinue ? <Button onClick={(event) => { setDirection("next") }} variant="contained" color="primary">Start &raquo;</Button> : null}
    </Container>
  );
}
