import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#111',
    },
  },
  typography: {
    allVariants: {
      color: '#FFF',
    },
    h2: {
      color: '#FFFF00',
    },
    button: {
      color: '#F00',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1.5rem',
      },
    },
  },
});

export default theme;
