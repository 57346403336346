const CATEGORIES = [
  {
    "title": "Chain of Fluids",
    "level": "Heavier",
    "tracks": [
      {
        "file": "1 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one, …now point to any player and they drink 2,…now to the left of this player they also drink 2,…now that player blows a kiss to any player and they must drink 3, to the right of the kissed player also has 3 drinks and this player gives the finger to any player who then must drink 4. "
      },
      {
        "file": "2 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one,…now the player on your left must drink 2, …now this player points to any player and they also drink 2,…now that player winks at any player and they must drink 3,… now to the right of the winked at player also has 3 drinks and this player gives the finger to any player who then must drink 4. "
      },
      {
        "file": "3 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one,…now the player on your right drinks 2,…now that player picks any player to drink 2,…now the player on the right of this picked player must have 3 drinks while pointing at another player who must also drink 3, and this pointed at player gives the finger to any player who then must drink 4. "
      },
      {
        "file": "4 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one,…now point to any player and they drink 2,…now to the right of that player they also drink 2, now that player on the right blows a kiss to any player and they must drink 3,…and to the left of the kissed player also has 3 drinks and this player gives the finger to any player who then must drink 4. "
      },
      {
        "file": "5 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one,…now the player on your left as 2 drinks,…now the player to their left also drinks 2,… now that player picks any player and they must drink 3,… now to the left of this picked player also has 3 drinks and that player gives the finger to any player who then must drink 4. "
      },
      {
        "file": "6 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one,…now the player on your right has 2 drinks,… now the player to their right also drinks 2,… now that player picks any player and they must drink 3,… now to the right of this picked player also has 3 drinks and that player gives the finger to any player who then must drink 4. "
      },
      {
        "file": "7 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one,… now the player on your right must drink 2,…now that player points to any player and they also drink 2,…now that player winks at any player and they must drink 3,…now that winked at player blows a kiss to any player who also has 3 drinks and that player gives the finger to any player who then must drink 4. "
      },
      {
        "file": "8 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one, now point to any player and they drink 2,…now to the left of that player they also drink 2,…now that player blows a kiss to any player and they must drink 3,…now this kissed player points to any player and they also have 3 drinks and that player gives the finger to any player who then must drink 4. "
      },
      {
        "file": "9 Chain of Fluids Complete.MP3",
        "transcript": "Ready, chosen player drink one,… now the player on your left as 2 drinks,… now the player to their left also drinks 2,…now this player picks any player and they must drink 3,…now the player on the picked players right also has 3 drinks and this player gives the finger to any player who then must drink 4. "
      }
    ]
  },
  {
    "title": "Continuation",
    "level": "Heavier",
    "tracks": [
      {
        "file": "1 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name a domestic beer, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "10 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the ways or different things you could do to make a pussy happy, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "11 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the ways or different things you could do to make a penis happy, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "12 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the ways or different things you could do to make an anus happy, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "13 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the ways or different things you could do to make titties happy, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "14 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the things alcohol makes us do, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "15 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the places you’ve given a hickey, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "16 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the different things you would do to a buzzed out friend, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "17 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name a mixed drink, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "18 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name a type of hard alcohol, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "19 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name drink condiments, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "2 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name an imported beer, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "20 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the ways or different things you could do to make a vagina sad, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "21 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the ways or different things you could do to make a penis sad, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "22 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the ways or different things you could do to make a penis longer, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "23 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name all the internal organs, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "24 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name things you don’t like about dicks, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "25 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name items found in a wallet or purse which might lead to sex, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "26 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name things that might grow on your ass, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "27 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name reasons why you might smell, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "28 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name reasons why a friend might stink, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "29 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different sex toys, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "3 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name different names for breasts, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "30 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name things you don’t like about pussy, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "31 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name what makes women horny, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "32 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name things you don’t like about beer, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "33 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name things you like about a lover’s mouth, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "34 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name reasons why you love beer, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "35 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, say what you might be thinking just before having sex, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "36 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, say what you might be thinking after having sex, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "37 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name what makes you horny, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "38 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different names for the guy’s penis, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "39 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different names for someone who has drank too much, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "4 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name different parts of the female crotch, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "40 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different names for sperm, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "41 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name things that might grow on your lips, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "42 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name the different parts of your friend’s body you would scratch, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "43 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, give me different words or phrases for, throwing up, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "44 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different names for condoms, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "45 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different forms of birth control, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "46 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different kinds of ass, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "47 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different colors, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "48 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different astrology signs, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "49 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different kinds of animals, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "5 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name different parts of the male crotch, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "50 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your right and so on, name different kinds of cars. no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "6 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name different parts of the woman found to be sexually attractive, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "7 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name different sex positions, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "8 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name different sexual acts, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      },
      {
        "file": "9 Continuation Complete.MP3",
        "transcript": "Starting with you and then continuing with the player on your left and so on, name different brand names of hard alcohol, no repeats, anyone missing, passing, or incorrect drinks one immediately and the next player in line continues until this round is out of time."
      }
    ]
  },
  {
    "title": "Have Some Suds",
    "level": "Lite",
    "tracks": [
      {
        "file": "1 Have Some Suds Complete.MP3",
        "transcript": "You would like to sneak a whiff of my panties. You’re wearing something pink. Like me, you currently possess a tight and warm vagina."
      },
      {
        "file": "10 Have Some Suds Complete.MP3",
        "transcript": "You ever had cum in your eye? You ever sniffed somebody’s underwear? You ever accidentally sat on a dick?"
      },
      {
        "file": "11 Have Some Suds Complete.MP3",
        "transcript": "You swallow because its gluten free. You like sticking your fingers in people. You ever ate a bug?"
      },
      {
        "file": "12 Have Some Suds Complete.MP3",
        "transcript": "You ever dated an asshole? You ever had a one night stand? You fucked one of the other players."
      },
      {
        "file": "13 Have Some Suds Complete.MP3",
        "transcript": "You ever scharted? You ever paid for sex? You ever charged for sex?"
      },
      {
        "file": "14 Have Some Suds Complete.MP3",
        "transcript": "You had Polish food today. You were or are in the military. You are currently more than ten thousand dollars in debt."
      },
      {
        "file": "15 Have Some Suds Complete.MP3",
        "transcript": "The word titties makes you chuckle. You currently have penis on your mind. You got caught masturbating."
      },
      {
        "file": "16 Have Some Suds Complete.MP3",
        "transcript": "You eat pussy because its gluten free. Have you ever got hit in the face by a Frisbee? Your nose was tickled by a pubic hair. "
      },
      {
        "file": "17 Have Some Suds Complete.MP3",
        "transcript": "You have or have had a big dick in your hands. You touched a clit with your tongue. You like nipples."
      },
      {
        "file": "18 Have Some Suds Complete.MP3",
        "transcript": "You ever snuck some booty? You had Italian food today. You’d rather be screwing now."
      },
      {
        "file": "19 Have Some Suds Complete.MP3",
        "transcript": "You ever jumped from a plane? You’ll smoke anything. You had a friend make you cum."
      },
      {
        "file": "2 Have Some Suds Complete.MP3",
        "transcript": "Hearing the word pussy makes you smell your fingers. Have you ever got stuck in a Hoola-hoop? You want to fuck one of the other players."
      },
      {
        "file": "20 Have Some Suds Complete.MP3",
        "transcript": "It’s well known or if you secretly like dicks. You had Indian food today. Your ass is a bit itchy."
      },
      {
        "file": "21 Have Some Suds Complete.MP3",
        "transcript": "You have a pimple on your ass. You had Chinese food today. You have a real job."
      },
      {
        "file": "22 Have Some Suds Complete.MP3",
        "transcript": "You’re wearing jewelry. You are currently missing front teeth. You are horny."
      },
      {
        "file": "23 Have Some Suds Complete.MP3",
        "transcript": "You cum easily. You have less then ten bucks on you. You plan on getting laid tonight."
      },
      {
        "file": "24 Have Some Suds Complete.MP3",
        "transcript": "You enjoy the aroma of sweaty balls. You have on something florescent. Have you ever choked on a hair?"
      },
      {
        "file": "25 Have Some Suds Complete.MP3",
        "transcript": "The only exercise you get is when you have the runs. You don’t have a tattoo. You don’t have a piercing. "
      },
      {
        "file": "26 Have Some Suds Complete.MP3",
        "transcript": "You’re shorter then 5 feet 8 inches. You have a tattoo. You are under 200 lbs."
      },
      {
        "file": "27 Have Some Suds Complete.MP3",
        "transcript": "You believe farting is a form of gluteal exercise. You had Soul food today. Your crotch is sore for whatever reason."
      },
      {
        "file": "28 Have Some Suds Complete.MP3",
        "transcript": "You ever visited a foreign country? You ever slept with a foreigner? The tip of your tongue has touched someone’s anus."
      },
      {
        "file": "29 Have Some Suds Complete.MP3",
        "transcript": "You have a piercing. Have you ever given someone the finger behind their back? You’re wearing something blue."
      },
      {
        "file": "3 Have Some Suds Complete.MP3",
        "transcript": "You are an alien. You think you’re the shit. Your butt hole is sore for whatever reason."
      },
      {
        "file": "30 Have Some Suds Complete.MP3",
        "transcript": "You had American food today. You like a piece of ass after a good meal. You are over 200 lbs."
      },
      {
        "file": "31 Have Some Suds Complete.MP3",
        "transcript": "Have you ever got gum in your hair? Have you ever got cum in your hair? Have you ever choked on cum while chewing gum?"
      },
      {
        "file": "32 Have Some Suds Complete.MP3",
        "transcript": "You played with yourself this week. You came more then 3 times this week. You make others cum easily."
      },
      {
        "file": "33 Have Some Suds Complete.MP3",
        "transcript": "You gave head this week. You got laid last night. You swing both ways."
      },
      {
        "file": "34 Have Some Suds Complete.MP3",
        "transcript": "You ever had sex in public? Have you had sex with more then one person in a 24 our period, ever? You got caught fucking."
      },
      {
        "file": "35 Have Some Suds Complete.MP3",
        "transcript": "You have balls. You have a sweet pussy, just like me. You have a fine ass."
      },
      {
        "file": "36 Have Some Suds Complete.MP3",
        "transcript": "You were once kicked in your ass crack by someone with pointy shoes. Have you ever reused a piece toilet paper? Have you ever used someone’s toothbrush without telling them?"
      },
      {
        "file": "37 Have Some Suds Complete.MP3",
        "transcript": "You’re taller then 5 feet 8 inches. You are a bad ass. It’s well known or if you secretly like pussy."
      },
      {
        "file": "38 Have Some Suds Complete.MP3",
        "transcript": "You are strictly dickly. You enjoy the scent of a sweaty cooch. You have all your teeth."
      },
      {
        "file": "39 Have Some Suds Complete.MP3",
        "transcript": "Have you ever choked on cum? You don’t have balls. You received head this week."
      },
      {
        "file": "4 Have Some Suds Complete.MP3",
        "transcript": "You ever showed your private in public? You were bit by a dog. You were scratched by a cat."
      },
      {
        "file": "40 Have Some Suds Complete.MP3",
        "transcript": "Have you ever got skin stuck in a zipper? You don’t have a vagina. You were abducted by aliens."
      },
      {
        "file": "5 Have Some Suds Complete.MP3",
        "transcript": "You ever had toilet paper tear on you resulting in a finger up your ass? Your crotch is currently hairless. You still live with your mommy or daddy."
      },
      {
        "file": "6 Have Some Suds Complete.MP3",
        "transcript": "You think your ass is fat. You’re on a diet. You know what cum taste like."
      },
      {
        "file": "7 Have Some Suds Complete.MP3",
        "transcript": "You ever stole something? You have a glass eye. You had Mexican food today. "
      },
      {
        "file": "8 Have Some Suds Complete.MP3",
        "transcript": "You’re a foreign exchange student. You speak a foreign language. You ever had a foreign object in your ass (be honest)?"
      },
      {
        "file": "9 Have Some Suds Complete.MP3",
        "transcript": "Your asshole is currently super tight. Your crotch requires constant attention. You ever came so hard that you also farted?"
      }
    ]
  },
  {
    "title": "Multiple Choice",
    "level": "Lite",
    "tracks": [
      {
        "file": "1 Multiple Choice Complete.MP3",
        "transcript": "The player on your right would, a) like a drink, b) like to pick someone to drink or c) have a beer bottle shoved up their ass? "
      },
      {
        "file": "10 Multiple Choice Complete.MP3",
        "transcript": "The player on your right a) would enjoy a drink b) would not enjoy a drink or c) does what their told like a good bitch? "
      },
      {
        "file": "100 Multiple Choice Complete.MP3",
        "transcript": "If the person on your right where hunting, what would they catch a) a cold, b) enough food for everyone or c) mad cow disease. "
      },
      {
        "file": "101 Multiple Choice Complete.MP3",
        "transcript": "The first time the person on your left had sex they were a) probably alone, b) probably passed out, c) probably on a farm or d) using their imagination as it hasn’t happened yet! "
      },
      {
        "file": "102 Multiple Choice Complete.MP3",
        "transcript": "The first time the person on your left had sex they were a) probably alone, b) probably passed out, c) probably on a farm or d) using their imagination as it hasn’t happened yet! "
      },
      {
        "file": "103 Multiple Choice Complete.MP3",
        "transcript": "The first time the person on your left had sex they were a) probably alone, b) probably passed out, c) probably on a farm or d) using their imagination as it hasn’t happened yet! "
      },
      {
        "file": "104 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left could grow something, what would it be a) a beautiful garden, b) a set of balls, c) a set of tits or d) all of the above."
      },
      {
        "file": "105 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left could grow something, what would it be a) a beautiful garden, b) a set of balls, c) a set of tits or d) all of the above."
      },
      {
        "file": "106 Multiple Choice Complete.MP3",
        "transcript": "What do you hear most often when you’re in bed a) silence, b) screaming, c) laughter or d) someone creeping around. "
      },
      {
        "file": "107 Multiple Choice Complete.MP3",
        "transcript": "What do you hear most often when you’re in bed a) silence, b) screaming, c) laughter or d) someone creeping around. "
      },
      {
        "file": "108 Multiple Choice Complete.MP3",
        "transcript": "When you daydream what’s it usually about a) something warm and cozy, b) something big and stiff, c) something small and tight or d) something warm big and stiff in your small tight and cozy ass! "
      },
      {
        "file": "109 Multiple Choice Complete.MP3",
        "transcript": "When you daydream what’s it usually about a) something warm and cozy, b) something big and stiff, c) something small and tight or d) something warm big and stiff in your small tight and cozy ass! "
      },
      {
        "file": "11 Multiple Choice Complete.MP3",
        "transcript": "The player on your right wouldn’t mind a) a cold one b) to share a cold one or c) a cold finger up their ass? "
      },
      {
        "file": "110 Multiple Choice Complete.MP3",
        "transcript": "When you daydream what’s it usually about a) something warm and cozy, b) something big and stiff, c) something small and tight or d) something warm big and stiff in your small tight and cozy ass! "
      },
      {
        "file": "111 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left where a super hero what amazing power would they possess a) The power to blow 100 men in seconds, b) the power to burn bushes with their eyes, c) The strength to bunch up their butt cheeks and split an atom or d) the ability to see the future and all our beer drinking needs."
      },
      {
        "file": "112 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left where a super hero what amazing power would they possess a) The power to blow 100 men in seconds, b) the power to burn bushes with their eyes, c) The strength to bunch up their butt cheeks and split an atom or d) the ability to see the future and all our beer drinking needs."
      },
      {
        "file": "113 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left where a super hero what amazing power would they possess a) The power to blow 100 men in seconds, b) the power to burn bushes with their eyes, c) The strength to bunch up their butt cheeks and split an atom or d) the ability to see the future and all our beer drinking needs."
      },
      {
        "file": "114 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right were a toy what toy would they be? a) a pull toy, b) a push toy or c) the most popular second hand toy ever."
      },
      {
        "file": "115 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right’s crotch was in a coloring book, what two color crayons would you need to color it? a) pink and red, b) black and blue, c) yellow and brown or d) you’re color blind. "
      },
      {
        "file": "116 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right’s crotch was in a coloring book, what two color crayons would you need to color it? a) pink and red, b) black and blue, c) yellow and brown or d) you’re color blind. "
      },
      {
        "file": "117 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right were a tool what would it be a) a hammer, b) a vice grip, c) a very dim flashlight or d) a bottle opener."
      },
      {
        "file": "118 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right were a tool what would it be a) a hammer, b) a vice grip, c) a very dim flashlight or d) a bottle opener."
      },
      {
        "file": "119 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right were a tool what would it be a) a hammer, b) a vice grip, c) a very dim flashlight or d) a bottle opener."
      },
      {
        "file": "12 Multiple Choice Complete.MP3",
        "transcript": "The player on your right wouldn’t mind a) a cold one b) to share a cold one or c) a cold finger up their ass? "
      },
      {
        "file": "120 Multiple Choice Complete.MP3",
        "transcript": "You wake up and there’s sticky white stuff all over your face you a) wash it off never giving it a second thought, b) leave it on because they say it’s good for the complexion, c) promise yourself not to eat ding-dongs in bed again or d) yell at the dog and forbid it from sleeping on the bed. "
      },
      {
        "file": "121 Multiple Choice Complete.MP3",
        "transcript": "You wake up and there’s sticky white stuff all over your face you a) wash it off never giving it a second thought, b) leave it on because they say it’s good for the complexion, c) promise yourself not to eat ding-dongs in bed again or d) yell at the dog and forbid it from sleeping on the bed. "
      },
      {
        "file": "122 Multiple Choice Complete.MP3",
        "transcript": "You’re stranded in the desert with the player on the right and you have only one twelve pack left to drink, so you a) share it and die together buzzed, b) sell it to him or her for twenty-minutes of oral sex and then die together or c) drink it, get drunk, kill them, eat them and survive until rescued. "
      },
      {
        "file": "123 Multiple Choice Complete.MP3",
        "transcript": "You’re stranded in the desert with the player on the right and you have only one twelve pack left to drink, so you a) share it and die together buzzed, b) sell it to him or her for twenty-minutes of oral sex and then die together or c) drink it, get drunk, kill them, eat them and survive until rescued. "
      },
      {
        "file": "124 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were a magician what would their greatest trick be a) balancing balls on their chin, b) pulling some kind of animal from their ass, c) holding their piss long enough to make their spit yellow or d) making something ten inches long disappear in any orifice of their body."
      },
      {
        "file": "125 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were a magician what would their greatest trick be a) balancing balls on their chin, b) pulling some kind of animal from their ass, c) holding their piss long enough to make their spit yellow or d) making something ten inches long disappear in any orifice of their body."
      },
      {
        "file": "126 Multiple Choice Complete.MP3",
        "transcript": "If the world was going to end tomorrow, you would most likely a) keep on drinking, b) try to screw one of the players or c) try to screw some of the players. "
      },
      {
        "file": "127 Multiple Choice Complete.MP3",
        "transcript": "If the world was going to end tomorrow, you would most likely a) keep on drinking, b) try to screw one of the players or c) try to screw some of the players. "
      },
      {
        "file": "128 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right were a toy what toy would they be? a) a pull toy, b) a push toy or c) the most popular second hand toy ever."
      },
      {
        "file": "129 Multiple Choice Complete.MP3",
        "transcript": "If you were a piece of clothing, you would a) fit like a glove, b) come in all shapes and sizes or c) be hand me downs. "
      },
      {
        "file": "13 Multiple Choice Complete.MP3",
        "transcript": "The player on your right thinks a) good head is on a beer b) good head is on their shoulders or c) good head is what everyone secretly says they give? "
      },
      {
        "file": "130 Multiple Choice Complete.MP3",
        "transcript": "If you were a piece of clothing, you would a) fit like a glove, b) come in all shapes and sizes or c) be hand me downs. "
      },
      {
        "file": "131 Multiple Choice Complete.MP3",
        "transcript": "If you were lunch, what would you be a) finger licking good, b) a happy meal or c) biggie sized."
      },
      {
        "file": "132 Multiple Choice Complete.MP3",
        "transcript": "If you were lunch, what would you be a) finger licking good, b) a happy meal or c) biggie sized."
      },
      {
        "file": "133 Multiple Choice Complete.MP3",
        "transcript": "If the player on the right were to describe you as a part of human anatomy they would say you were a) all heart b) a big dick or c) an asshole. "
      },
      {
        "file": "134 Multiple Choice Complete.MP3",
        "transcript": "If the player on the right were to describe you as a part of human anatomy they would say you were a) all heart b) a big dick or c) an asshole. "
      },
      {
        "file": "135 Multiple Choice Complete.MP3",
        "transcript": "Walking passed an open hallway the sounds of love making can be heard. In this situation the player on the left would probably a) quickly and quietly continue walking, b) sneak a peek undetected or c) take off all their clothes and pretend to accidentally fall into the hallway hoping to get in on the action. "
      },
      {
        "file": "136 Multiple Choice Complete.MP3",
        "transcript": "Walking passed an open hallway the sounds of love making can be heard. In this situation the player on the left would probably a) quickly and quietly continue walking, b) sneak a peek undetected or c) take off all their clothes and pretend to accidentally fall into the hallway hoping to get in on the action. "
      },
      {
        "file": "137 Multiple Choice Complete.MP3",
        "transcript": "What do you think is the player on your right’s favorite sexual position is it a) 69, two can chew, b) on their knees, one can chew, c) bent over or d) seated, watching a porno. "
      },
      {
        "file": "138 Multiple Choice Complete.MP3",
        "transcript": "What do you think is the player on your right’s favorite sexual position is it a) 69, two can chew, b) on their knees, one can chew, c) bent over or d) seated, watching a porno. "
      },
      {
        "file": "139 Multiple Choice Complete.MP3",
        "transcript": "What do you think is the player on your right’s favorite sexual position is it a) 69, two can chew, b) on their knees, one can chew, c) bent over or d) seated, watching a porno.  "
      },
      {
        "file": "14 Multiple Choice Complete.MP3",
        "transcript": "The player on your right thinks a) good head is on a beer b) good head is on their shoulders or c) good head is what everyone secretly says they give? "
      },
      {
        "file": "140 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were in the woods and accidentally sat on a snake, they most likely would a) kill it instantly, b) let nature take its course or c) bunch up their cheeks and hope for the best."
      },
      {
        "file": "141 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were in the woods and accidentally sat on a snake, they most likely would a) kill it instantly, b) let nature take its course or c) bunch up their cheeks and hope for the best."
      },
      {
        "file": "142 Multiple Choice Complete.MP3",
        "transcript": "You just got caught by your folks, giving head, so you a) pretend not to notice them and continue on, b) think this question is bullshit because you don’t give head or c) mumble something that’s totally not-understandable, as you clean your face. "
      },
      {
        "file": "143 Multiple Choice Complete.MP3",
        "transcript": "You just got caught by your folks, giving head, so you a) pretend not to notice them and continue on, b) think this question is bullshit because you don’t give head or c) mumble something that’s totally not-understandable, as you clean your face. "
      },
      {
        "file": "144 Multiple Choice Complete.MP3",
        "transcript": "The most embarrassing incident you had during sex was a) You ran out of condoms, b) you ran out of knockout drops or c) You ran out of dirty magazines. "
      },
      {
        "file": "145 Multiple Choice Complete.MP3",
        "transcript": "The most embarrassing incident you had during sex was a) You ran out of condoms, b) you ran out of knockout drops or c) You ran out of dirty magazines. "
      },
      {
        "file": "146 Multiple Choice Complete.MP3",
        "transcript": "The person on your left has this experience when spreading those legs, a) an echo, hello, b) a tight situation, c) a gusher or d) thick heavy brush. "
      },
      {
        "file": "147 Multiple Choice Complete.MP3",
        "transcript": "The person on your left has this experience when spreading those legs, a) an echo, hello, b) a tight situation, c) a gusher or d) thick heavy brush. "
      },
      {
        "file": "148 Multiple Choice Complete.MP3",
        "transcript": "The person on your left has this experience when spreading those legs, a) an echo, hello, b) a tight situation, c) a gusher or d) thick heavy brush. "
      },
      {
        "file": "149 Multiple Choice Complete.MP3",
        "transcript": "The person on your left has this experience when spreading those legs, a) an echo, hello, b) a tight situation, c) a gusher or d) thick heavy brush. "
      },
      {
        "file": "15 Multiple Choice Complete.MP3",
        "transcript": "The player on your right knows for a fact that a) your thirsty b) their thirsty or c) everyone’s thirsty? "
      },
      {
        "file": "150 Multiple Choice Complete.MP3",
        "transcript": "You just awoke from a night of heavy drinking and you’re lying in someone’s bed with someone behind you. There’s something rather large wedged in your anus you a) carefully with some pain, slip it out and slip out un-noticed, b) you slowly back-up and snuggle the pillow or c) this wouldn’t ever happen to you. "
      },
      {
        "file": "151 Multiple Choice Complete.MP3",
        "transcript": "You just awoke from a night of heavy drinking and you’re lying in someone’s bed with someone behind you. There’s something rather large wedged in your anus you a) carefully with some pain, slip it out and slip out un-noticed, b) you slowly back-up and snuggle the pillow or c) this wouldn’t ever happen to you. "
      },
      {
        "file": "152 Multiple Choice Complete.MP3",
        "transcript": "You just awoke from a night of heavy drinking and you’re lying in someone’s bed with someone behind you. There’s something rather large wedged in your anus you a) carefully with some pain, slip it out and slip out un-noticed, b) you slowly back-up and snuggle the pillow or c) this wouldn’t ever happen to you. "
      },
      {
        "file": "153 Multiple Choice Complete.MP3",
        "transcript": "You just banged like hell with someone, you take a look at their crotch and you think a) I better let it cool off, b) 18 or c) I got another 8 hours to kill. "
      },
      {
        "file": "154 Multiple Choice Complete.MP3",
        "transcript": "You just banged like hell with someone, you take a look at their crotch and you think a) I better let it cool off, b) 18 or c) I got another 8 hours to kill. "
      },
      {
        "file": "155 Multiple Choice Complete.MP3",
        "transcript": "You just banged like hell with someone, you take a look at their crotch and you think a) I better let it cool off, b) 18 or c) I got another 8 hours to kill. "
      },
      {
        "file": "156 Multiple Choice Complete.MP3",
        "transcript": "While standing raise one leg up, close one eye, extend one arm out and start making big circles."
      },
      {
        "file": "157 Multiple Choice Complete.MP3",
        "transcript": "Behind your back your Ma says this about you a) That’s my smart one, b) That’s the good looking one or c). That’s a nut I should’ve swallowed. "
      },
      {
        "file": "158 Multiple Choice Complete.MP3",
        "transcript": "Behind your back your Ma says this about you a) That’s my smart one, b) That’s the good looking one or c). That’s a nut I should’ve swallowed. "
      },
      {
        "file": "159 Multiple Choice Complete.MP3",
        "transcript": "Thumb wars with the player on your right, get ready and 3,2,1, begin loser has a drink."
      },
      {
        "file": "16 Multiple Choice Complete.MP3",
        "transcript": "The player on your right knows for a fact that a) your thirsty b) their thirsty or c) everyone’s thirsty? "
      },
      {
        "file": "160 Multiple Choice Complete.MP3",
        "transcript": "Oh wait thumb wars with the player on your left, get ready and 3,2,1, begin loser has a drink."
      },
      {
        "file": "161 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, no thinking about pussy, if you do, you must drink one. Some of you might as well get a refill now!"
      },
      {
        "file": "162 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, not, because rules are for pussies, so all rules are cancelled!"
      },
      {
        "file": "163 Multiple Choice Complete.MP3",
        "transcript": "Oh wait thumb wars with any player, get ready and 3,2,1, begin loser has a drink."
      },
      {
        "file": "164 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, no thinking about cocks, if you do, you must drink one. And don’t think we can’t tell!"
      },
      {
        "file": "165 Multiple Choice Complete.MP3",
        "transcript": "Bam, you’re a fucking idiot! Finish your entire drink."
      },
      {
        "file": "166 Multiple Choice Complete.MP3",
        "transcript": "Bam, you’re a fucking bad ass! Pick someone to finish the rest of their drink."
      },
      {
        "file": "167 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were to have surgery on their crotch they would a) Have certain enlargements done, b) Have certain reductions done, c) Have a tattoo removed or d) change the plumbing all together."
      },
      {
        "file": "168 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right were a toy what toy would they be, a) a pull toy, b) a push toy or c) the most popular second hand toy ever. "
      },
      {
        "file": "169 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right were a toy what toy would they be, a) a pull toy, b) a push toy or c) the most popular second hand toy ever. "
      },
      {
        "file": "17 Multiple Choice Complete.MP3",
        "transcript": "This question is for you, just have a drink, you slow mother fucker."
      },
      {
        "file": "170 Multiple Choice Complete.MP3",
        "transcript": "They say curiosity killed the pussy cat, but that didn’t stop the player on your right from a) touching it, b) smelling it, c) licking it or d) doing all three and then eating it, thoroughly! "
      },
      {
        "file": "171 Multiple Choice Complete.MP3",
        "transcript": "Perform the field sobriety test. Standing with feet together, arms at your sides, close your eyes, raise one leg up and while standing on one foot extend arms out to horizontal, now bring your right hand across and touch your nose, hold it, 3-2-1."
      },
      {
        "file": "172 Multiple Choice Complete.MP3",
        "transcript": "You’re a Cop, pick any player to perform the field sobriety test. Standing with feet together, arms at your sides, close your eyes, raise one leg up and while standing on one foot extend arms out to horizontal, now bring your left hand across and touch your nose, hold it, 3-2-1."
      },
      {
        "file": "173 Multiple Choice Complete.MP3",
        "transcript": "No question. The answer is D. for drink, give one out."
      },
      {
        "file": "174 Multiple Choice Complete.MP3",
        "transcript": "Your butt is sooo tight that, a) They couldn’t pull a pin out of your ass with a bulldozer, b) When you fart only the dogs can hear it or c) you can hold, securely, a twenty pound greased up bowling ball. "
      },
      {
        "file": "175 Multiple Choice Complete.MP3",
        "transcript": "Your butt is sooo tight that, a) They couldn’t pull a pin out of your ass with a bulldozer, b) When you fart only the dogs can hear it or c) you can hold, securely, a twenty pound greased up bowling ball. "
      },
      {
        "file": "176 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were to have surgery on their crotch they would a) Have certain enlargements done, b) Have certain reductions done, c) Have a tattoo removed or d) change the plumbing all together."
      },
      {
        "file": "177 Multiple Choice Complete.MP3",
        "transcript": "They say curiosity killed the pussy cat, but that didn’t stop the player on your right from a) touching it, b) smelling it, c) licking it or d) doing all three and then eating it, thoroughly! "
      },
      {
        "file": "178 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were to have surgery on their crotch they would a) Have certain enlargements done, b) Have certain reductions done, c) Have a tattoo removed or d) change the plumbing all together. "
      },
      {
        "file": "179 Multiple Choice Complete.MP3",
        "transcript": "The player on your right was busted naked a) at the zoo, again, b) on a farm petting a rooster or c) at the corner bar. "
      },
      {
        "file": "18 Multiple Choice Complete.MP3",
        "transcript": "This question is for you, just pick someone who needs one, you dumb ass."
      },
      {
        "file": "180 Multiple Choice Complete.MP3",
        "transcript": "The player on your right was busted naked a) at the zoo, again, b) on a farm petting a rooster or c) at the corner bar. "
      },
      {
        "file": "181 Multiple Choice Complete.MP3",
        "transcript": "Clock challenge, on my mark you start drinking until I say stop."
      },
      {
        "file": "182 Multiple Choice Complete.MP3",
        "transcript": "Clock challenge, on my mark you start drinking until I say stop."
      },
      {
        "file": "183 Multiple Choice Complete.MP3",
        "transcript": "Clock challenge, on my mark you start drinking until I say stop."
      },
      {
        "file": "184 Multiple Choice Complete.MP3",
        "transcript": "You are one good looking some bitch, everyone toast to your fine ass self! Drink up!"
      },
      {
        "file": "185 Multiple Choice Complete.MP3",
        "transcript": "The answer is D. for drink! Have one you stupid looking mother fucker. I know there wasn’t a question; just making an observation cause there’s always one in the crowd and it’s you!"
      },
      {
        "file": "186 Multiple Choice Complete.MP3",
        "transcript": "Clock challenge, on my mark you start drinking until I say stop. "
      },
      {
        "file": "187 Multiple Choice Complete.MP3",
        "transcript": "Clock challenge, on my mark you start drinking until I say stop. "
      },
      {
        "file": "188 Multiple Choice Complete.MP3",
        "transcript": "Clock challenge, on my mark you start drinking until I say stop. "
      },
      {
        "file": "189 Multiple Choice Complete.MP3",
        "transcript": "If you’re a chick this refers to you, but if you’re a dude, this refers to your chick’s pussy. Pick the sound that represents your pussy best. a) chu-chu, b) snapper, c) echo, Hello or d) pew. "
      },
      {
        "file": "19 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right where faced with death or sucking a dick to live what would happen? a) instantaneous death b) intense confusion or c) they would become immortal. "
      },
      {
        "file": "190 Multiple Choice Complete.MP3",
        "transcript": "If you’re a chick this refers to you, but if you’re a dude, this refers to your chick’s pussy. Pick the sound that represents your pussy best. a) chu-chu, b) snapper, c) echo, Hello or d) pew. "
      },
      {
        "file": "191 Multiple Choice Complete.MP3",
        "transcript": "If you’re a chick this refers to you, but if you’re a dude, this refers to your chick’s pussy. Pick the sound that represents your pussy best. a) chu-chu, b) snapper, c) echo, Hello or d) pew. "
      },
      {
        "file": "192 Multiple Choice Complete.MP3",
        "transcript": "If you’re a dude this refers to you but if you’re a chick this refers to your dude’s dick. Pick the sound that represents your peter best. a) audience cheering, b) horse, c) wimpy or d) a hammer drill. "
      },
      {
        "file": "193 Multiple Choice Complete.MP3",
        "transcript": "If you’re a dude this refers to you but if you’re a chick this refers to your dude’s dick. Pick the sound that represents your peter best. a) audience cheering, b) horse, c) wimpy or d) a hammer drill. "
      },
      {
        "file": "194 Multiple Choice Complete.MP3",
        "transcript": "If you’re a chick, pick a dick to drink, if you’re a dude have one."
      },
      {
        "file": "195 Multiple Choice Complete.MP3",
        "transcript": "If you’re a chick, pick a dick to drink, if you’re a dude have one."
      },
      {
        "file": "196 Multiple Choice Complete.MP3",
        "transcript": "You’re naked in the woods and this sound represents most closely what your ass smells like a) or b)? "
      },
      {
        "file": "197 Multiple Choice Complete.MP3",
        "transcript": "You’re naked in the woods and this sound represents most closely what your ass smells like a) or b)? "
      },
      {
        "file": "198 Multiple Choice Complete.MP3",
        "transcript": "You would run a marathon if a) your balls weren’t so big, b) your lips weren’t so flappy or c) it didn’t cause you to shit yourself by the second block."
      },
      {
        "file": "199 Multiple Choice Complete.MP3",
        "transcript": "You would run a marathon if a) your balls weren’t so big, b) your lips weren’t so flappy or c) it didn’t cause you to shit yourself by the second block."
      },
      {
        "file": "2 Multiple Choice Complete.MP3",
        "transcript": "The player on your right would, a) like a drink, b) like to pick someone to drink or c) have a beer bottle shoved up their ass? "
      },
      {
        "file": "20 Multiple Choice Complete.MP3",
        "transcript": "If the player on your right where faced with death or sucking a dick to live what would happen? a) instantaneous death b) intense confusion or c) they would become immortal. "
      },
      {
        "file": "200 Multiple Choice Complete.MP3",
        "transcript": "You would run a marathon if a) your balls weren’t so big, b) your lips weren’t so flappy or c) it didn’t cause you to shit yourself by the second block."
      },
      {
        "file": "21 Multiple Choice Complete.MP3",
        "transcript": "The player on your right loves it, a) when someone spreads butter on their toast b) when someone spreads jelly on their crackers or c) when someone spreads their ass cheeks? "
      },
      {
        "file": "22 Multiple Choice Complete.MP3",
        "transcript": "The player on your right loves it, a) when someone spreads butter on their toast b) when someone spreads jelly on their crackers or c) when someone spreads their ass cheeks? "
      },
      {
        "file": "23 Multiple Choice Complete.MP3",
        "transcript": "The player on your right would rather be a) drinking instead of listening to me b) making you drink instead of listening to me or c) slowly licking on me?"
      },
      {
        "file": "24 Multiple Choice Complete.MP3",
        "transcript": "The player on your right would rather be a) drinking instead of listening to me b) making you drink instead of listening to me or c) slowly licking on me?"
      },
      {
        "file": "25 Multiple Choice Complete.MP3",
        "transcript": "The player on your right thinks a) a tight ass is a well-equipped donkey b) a big cock is a roster on steroids or c) a large pussy is someone six foot four who is afraid of you? "
      },
      {
        "file": "26 Multiple Choice Complete.MP3",
        "transcript": "The player on your right thinks a) a tight ass is a well-equipped donkey b) a big cock is a roster on steroids or c) a large pussy is someone six foot four who is afraid of you? "
      },
      {
        "file": "27 Multiple Choice Complete.MP3",
        "transcript": "The person on your left was once caught in school smoking, a) a cigarette b) a joint or c) a Johnson? "
      },
      {
        "file": "28 Multiple Choice Complete.MP3",
        "transcript": "The person on your left was once caught in school smoking, a) a cigarette b) a joint or c) a Johnson? "
      },
      {
        "file": "29 Multiple Choice Complete.MP3",
        "transcript": "The person on your left just won a million dollars the first thing they would do is a) find someone to blow some of it with b) find someone to blow all of it with or c) just find someone to blow? "
      },
      {
        "file": "3 Multiple Choice Complete.MP3",
        "transcript": "The player on your right secretly wants to a) suck down a drink, b) have you suck down a drink or c) take a trip to the farm and suck down a donkey? "
      },
      {
        "file": "30 Multiple Choice Complete.MP3",
        "transcript": "The person on your left just won a million dollars the first thing they would do is a) find someone to blow some of it with b) find someone to blow all of it with or c) just find someone to blow? "
      },
      {
        "file": "31 Multiple Choice Complete.MP3",
        "transcript": "The person on your left a) needs a drink b) needs to think they’re in charge or c) needs soap and water. "
      },
      {
        "file": "32 Multiple Choice Complete.MP3",
        "transcript": "The person on your left a) needs a drink b) needs to think they’re in charge or c) needs soap and water. "
      },
      {
        "file": "33 Multiple Choice Complete.MP3",
        "transcript": "The person on your left a) really sucks to be around b) really really sucks to be around or c) really is around to suck, really?"
      },
      {
        "file": "34 Multiple Choice Complete.MP3",
        "transcript": "The person on your left a) really sucks to be around b) really really sucks to be around or c) really is around to suck, really?"
      },
      {
        "file": "35 Multiple Choice Complete.MP3",
        "transcript": "The person on your left a) is wearing panties b) is trying to get into someone’s panties or c) just likes to hear the word panties? "
      },
      {
        "file": "36 Multiple Choice Complete.MP3",
        "transcript": "The person on your left a) is wearing panties b) is trying to get into someone’s panties or c) just likes to hear the word panties? "
      },
      {
        "file": "37 Multiple Choice Complete.MP3",
        "transcript": "The person on your left with the proper training a) could become an awesome solder b) could become an exquisite chef or c) could suck the color out of a marble? "
      },
      {
        "file": "38 Multiple Choice Complete.MP3",
        "transcript": "The person on your left with the proper training a) could become an awesome solder b) could become an exquisite chef or c) could suck the color out of a marble? "
      },
      {
        "file": "39 Multiple Choice Complete.MP3",
        "transcript": "The person on your left with the proper training a) could become an awesome solder b) could become an exquisite chef or c) could suck the color out of a marble? "
      },
      {
        "file": "4 Multiple Choice Complete.MP3",
        "transcript": "The player on your right secretly wants to a) suck down a drink, b) have you suck down a drink or c) take a trip to the farm and suck down a donkey? "
      },
      {
        "file": "40 Multiple Choice Complete.MP3",
        "transcript": "A man is sitting on a bus with an erection, if the person on your left where faced with this situation how would they react a) by pretending not to notice it b) by letting this person know you notice it or c) by pretending to slip and fall landing on his lap. "
      },
      {
        "file": "41 Multiple Choice Complete.MP3",
        "transcript": "A man is sitting on a bus with an erection, if the person on your left where faced with this situation how would they react a) by pretending not to notice it b) by letting this person know you notice it or c) by pretending to slip and fall landing on his lap. "
      },
      {
        "file": "42 Multiple Choice Complete.MP3",
        "transcript": "A woman is sitting across from you on the bus she’s wearing a skirt her legs are spread far apart enough for you to notice some bush, if the person on your left where in this situation they would a) gesture to her discreetly to close her legs b) pretend to fall forward and then fake a neck spasm c) see if the carpeting matches the drapes or d) all of the above but in reverse? "
      },
      {
        "file": "43 Multiple Choice Complete.MP3",
        "transcript": "A woman is sitting across from you on the bus she’s wearing a skirt her legs are spread far apart enough for you to notice some bush, if the person on your left where in this situation they would a) gesture to her discreetly to close her legs b) pretend to fall forward and then fake a neck spasm c) see if the carpeting matches the drapes or d) all of the above but in reverse? "
      },
      {
        "file": "44 Multiple Choice Complete.MP3",
        "transcript": "The person on your left most likely would finish the sentence; If I had it my way I would be a) a princess b) a prince or c) taking it up the butt? "
      },
      {
        "file": "45 Multiple Choice Complete.MP3",
        "transcript": "The person on your left most likely would finish the sentence; If I had it my way I would be a) a princess b) a prince or c) taking it up the butt? "
      },
      {
        "file": "46 Multiple Choice Complete.MP3",
        "transcript": "The person on your left most likely would finish the sentence; If it where dirty I would a) still let it in b) still put it in or c) just gargle afterward. "
      },
      {
        "file": "47 Multiple Choice Complete.MP3",
        "transcript": "The person on your left most likely would finish the sentence; If it where dirty I would a) still let it in b) still put it in or c) just gargle afterward. "
      },
      {
        "file": "48 Multiple Choice Complete.MP3",
        "transcript": "The person on your left most likely would finish the sentence; If it where dirty I would a) still let it in b) still put it in or c) just gargle afterward. "
      },
      {
        "file": "49 Multiple Choice Complete.MP3",
        "transcript": "Its last call the person on your left is most likely a) totally shit faced b) totally shit faced and half naked or c) totally shit faced half naked and suddenly your best friend?  "
      },
      {
        "file": "5 Multiple Choice Complete.MP3",
        "transcript": "You got 3 seconds, how much is 12 + 12 + 12? "
      },
      {
        "file": "50 Multiple Choice Complete.MP3",
        "transcript": "Its last call the person on your left is most likely a) totally shit faced b) totally shit faced and half naked or c) totally shit faced half naked and suddenly your best friend?  "
      },
      {
        "file": "51 Multiple Choice Complete.MP3",
        "transcript": "The person on your left most likely would finish the sentence, “If it where raining pussy” a) I would get hit with a dick, b) I would stay indoors or c) I would be singing in the rain. "
      },
      {
        "file": "52 Multiple Choice Complete.MP3",
        "transcript": "The person on your left most likely would finish the sentence, “If it where raining pussy” a) I would get hit with a dick, b) I would stay indoors or c) I would be singing in the rain. "
      },
      {
        "file": "53 Multiple Choice Complete.MP3",
        "transcript": "The person on your left most likely would finish the sentence, “If it where raining pussy” a) I would get hit with a dick, b) I would stay indoors or c) I would be singing in the rain. "
      },
      {
        "file": "54 Multiple Choice Complete.MP3",
        "transcript": "The player on the left sees a person of the opposite sex standing in an alley partially dressed they a) appear to them as half-dressed b) appear to them as half naked or c) appear to them as just about fucked. "
      },
      {
        "file": "55 Multiple Choice Complete.MP3",
        "transcript": "The player on the left sees a person of the opposite sex standing in an alley partially dressed they a) appear to them as half-dressed b) appear to them as half naked or c) appear to them as just about fucked. "
      },
      {
        "file": "56 Multiple Choice Complete.MP3",
        "transcript": "Don’t you think it’s time to pick up your lazy ass and get everyone a round? Do it!"
      },
      {
        "file": "57 Multiple Choice Complete.MP3",
        "transcript": "Don’t you think you could be a bit more useful, pick up all the mess and trash around everyone? Will wait on you. Yea right, get it in gear!"
      },
      {
        "file": "58 Multiple Choice Complete.MP3",
        "transcript": "You have 3 seconds to repeat after me “icca-bicca-soda cracker icca-bicca boo icca-bicca-soda cracker out goes yo-yo-yo-you! "
      },
      {
        "file": "59 Multiple Choice Complete.MP3",
        "transcript": "have 3 seconds to repeat after me, “2-4-6-8-10” but in reverse. "
      },
      {
        "file": "6 Multiple Choice Complete.MP3",
        "transcript": "The player on your right would a) like for you to have a drink b) like to make someone else have a drink or c) like to spend the good part of this evening with something large up their ass? "
      },
      {
        "file": "60 Multiple Choice Complete.MP3",
        "transcript": "Here is a rule. All rules are cancelled!"
      },
      {
        "file": "61 Multiple Choice Complete.MP3",
        "transcript": "You have 3 seconds to repeat after me. ”Old McKenna had a farm and on the farm he had a sheep and with the sheep he would creep.” "
      },
      {
        "file": "62 Multiple Choice Complete.MP3",
        "transcript": "You have 5 seconds to repeat after me, “Sweet Sally saucer stood a virgin long, until Satan Sam slider pushed in his dong!” "
      },
      {
        "file": "63 Multiple Choice Complete.MP3",
        "transcript": "You have 5 seconds to repeat after me, “Give the honey a winky and maybe you’ll get a stinky pinky.”"
      },
      {
        "file": "64 Multiple Choice Complete.MP3",
        "transcript": "You have 5 seconds to repeat after me, “Itsy-bitsy hairy spider got stabbed by the spout, all night long the neighbors could hear it getting stretched out!” "
      },
      {
        "file": "65 Multiple Choice Complete.MP3",
        "transcript": "You have 6 seconds to repeat after me, “99 bottles of beer on the wall, 99 bottles of beer, if one of those bottles should happen to fall, my mom’s gonna come in here and kill us all!” "
      },
      {
        "file": "66 Multiple Choice Complete.MP3",
        "transcript": "You have 5 seconds to repeat after me, “10-20-40-60-80-100”, but in reverse. "
      },
      {
        "file": "67 Multiple Choice Complete.MP3",
        "transcript": "For two drinks, you have 5 seconds to repeat after me, “Pattie’s pretty pink pleasant pussy was planted by Peter’s powerful purple protruding penis!” "
      },
      {
        "file": "68 Multiple Choice Complete.MP3",
        "transcript": "You have 3 seconds to repeat after me, “Bobby’s big boner had to roam, until Betty’s big booty gave him a home.” "
      },
      {
        "file": "69 Multiple Choice Complete.MP3",
        "transcript": "Which sound would best represent the person on your left if they were naked. a) Boing, b) Baboom or c) Fading sound. "
      },
      {
        "file": "7 Multiple Choice Complete.MP3",
        "transcript": "The player on your right would a) like for you to have a drink b) like to make someone else have a drink or c) like to spend the good part of this evening with something large up their ass? "
      },
      {
        "file": "70 Multiple Choice Complete.MP3",
        "transcript": "Which sound would best represent the person on your left if they were naked. a) Boing, b) Baboom or c) Fading sound. "
      },
      {
        "file": "71 Multiple Choice Complete.MP3",
        "transcript": "Which sound would best represent the person on your left if they were naked. a) Boing, b) Baboom or c) Fading sound. "
      },
      {
        "file": "72 Multiple Choice Complete.MP3",
        "transcript": "The player on your left is good at a) juggling fuzzy balls, b) packing little boxes or c) only what they’re doing now! "
      },
      {
        "file": "73 Multiple Choice Complete.MP3",
        "transcript": "The player on your left is good at a) juggling fuzzy balls, b) packing little boxes or c) only what they’re doing now! "
      },
      {
        "file": "74 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were a piece of lumber, would you a) screw it, b) saw it in half or c) not use it because it’s warped. "
      },
      {
        "file": "75 Multiple Choice Complete.MP3",
        "transcript": "If the player on your left were a piece of lumber, would you a) screw it, b) saw it in half or c) not use it because it’s warped. "
      },
      {
        "file": "76 Multiple Choice Complete.MP3",
        "transcript": "During a doctor’s visit the player on the right was asked to drop their pants and after doing so the doctor a) laughs his ass off, b) holds his breath or c) asks this person out on a date. "
      },
      {
        "file": "77 Multiple Choice Complete.MP3",
        "transcript": "During a doctor’s visit the player on the right was asked to drop their pants and after doing so the doctor a) laughs his ass off, b) holds his breath or c) asks this person out on a date. "
      },
      {
        "file": "78 Multiple Choice Complete.MP3",
        "transcript": "During a doctor’s visit the player on the right was asked to drop their pants and after doing so the doctor a) laughs his ass off, b) holds his breath or c) asks this person out on a date. "
      },
      {
        "file": "79 Multiple Choice Complete.MP3",
        "transcript": "If the person on your right’s ass was any bigger a) you would recommend a license plate (for that big ass), b) It would actually fill both hands or c) it might look as good as yours."
      },
      {
        "file": "8 Multiple Choice Complete.MP3",
        "transcript": "The player on your right would a) like for you to have a drink b) like to make someone else have a drink or c) like to spend the good part of this evening with something large up their ass? "
      },
      {
        "file": "80 Multiple Choice Complete.MP3",
        "transcript": "If the person on your right’s ass was any bigger a) you would recommend a license plate (for that big ass), b) It would actually fill both hands or c) it might look as good as yours."
      },
      {
        "file": "81 Multiple Choice Complete.MP3",
        "transcript": "Your kids in the first grade and its report card time, you show up a) in super tight clothes, b) late and confused, c) drunk or d) all of the previous mentioned. "
      },
      {
        "file": "82 Multiple Choice Complete.MP3",
        "transcript": "Your kids in the first grade and its report card time, you show up a) in super tight clothes, b) late and confused, c) drunk or d) all of the previous mentioned. "
      },
      {
        "file": "83 Multiple Choice Complete.MP3",
        "transcript": "If you were a piece of food what would it be a) a sloppy Joe, b) a sloppy taco or c) anything that makes the eater sloppy. "
      },
      {
        "file": "84 Multiple Choice Complete.MP3",
        "transcript": "If you were a piece of food what would it be a) a sloppy Joe, b) a sloppy taco or c) anything that makes the eater sloppy. "
      },
      {
        "file": "85 Multiple Choice Complete.MP3",
        "transcript": "If the person on your left were a piece of fruit what would it be a) a banana, b) a coconut, c) a cherry or d) reconstituted. "
      },
      {
        "file": "86 Multiple Choice Complete.MP3",
        "transcript": "If the person on your left were a piece of fruit what would it be a) a banana, b) a coconut, c) a cherry or d) reconstituted. "
      },
      {
        "file": "87 Multiple Choice Complete.MP3",
        "transcript": "If the person on your right were meat what would it be a) porky, b) beefy, c) fishy or d) processed. "
      },
      {
        "file": "88 Multiple Choice Complete.MP3",
        "transcript": "If the person on your right were meat what would it be a) porky, b) beefy, c) fishy or d) processed. "
      },
      {
        "file": "89 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, no saying the word drink, if you do you have one."
      },
      {
        "file": "9 Multiple Choice Complete.MP3",
        "transcript": "The player on your right a) would enjoy a drink b) would not enjoy a drink or c) does what their told like a good bitch? "
      },
      {
        "file": "90 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, no saying the word fuck, if you do you have a drink."
      },
      {
        "file": "91 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, no pointing, if you do you have a drink."
      },
      {
        "file": "92 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, no sliding of glasses, cups or bottles, if you do you have a drink."
      },
      {
        "file": "93 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, all rules are cancelled."
      },
      {
        "file": "94 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, players cannot use proper names when addressing other players, if you do you have a drink."
      },
      {
        "file": "95 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, no cussing, if you do you have a fucking drink."
      },
      {
        "file": "96 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, everyone must tap twice before they drink, if you don’t you add on an additional drink."
      },
      {
        "file": "97 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, no more fucking rules."
      },
      {
        "file": "98 Multiple Choice Complete.MP3",
        "transcript": "Here’s a rule, you must solute before you drink, if you don’t you add on an additional drink."
      },
      {
        "file": "99 Multiple Choice Complete.MP3",
        "transcript": "If the person on your right where hunting, what would they catch a) a cold, b) enough food for everyone or c) mad cow disease. "
      }
    ]
  },
  {
    "title": "Rock, Paper, Scissors",
    "level": "Lite",
    "tracks": [
      {
        "file": "1 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with."
      },
      {
        "file": "10 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "11 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "12 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "13 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "14 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "15 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "16 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "17 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "18 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick 2 players to compete with. "
      },
      {
        "file": "19 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "2 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with."
      },
      {
        "file": "20 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "21 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "22 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "23 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "24 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "25 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "26 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "27 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, Paper, Scissors! Quickly pick 3 other players to compete with."
      },
      {
        "file": "3 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with."
      },
      {
        "file": "4 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with."
      },
      {
        "file": "5 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with."
      },
      {
        "file": "6 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with."
      },
      {
        "file": "7 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with."
      },
      {
        "file": "8 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with."
      },
      {
        "file": "9 Rock, Paper, Scissors Complete.MP3",
        "transcript": "Rock, paper, scissors! Quickly pick another player to compete with. "
      }
    ]
  },
  {
    "title": "True or False, Fact or Fiction",
    "level": "Lite",
    "tracks": [
      {
        "file": "1 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the guy on your right’s penis is so small that when he pees his balls probably get wet?"
      },
      {
        "file": "10 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the dude on your right’s thing is so thin that he gets two kinds of pussy with every woman, the left side of the pussy or the right side or the pussy?"
      },
      {
        "file": "11 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you could, suck my booty sooo good, that you could tell what kind of toothpaste I use?"
      },
      {
        "file": "12 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you could, suck my booty sooo good, that you could tell what kind of toothpaste I use?"
      },
      {
        "file": "13 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you could suck a booty so good that for the next two weeks they’d fart, fresh air?"
      },
      {
        "file": "14 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you could suck a booty so good that for the next two weeks they’d fart, fresh air?"
      },
      {
        "file": "15 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you heard that the guy on your left’s dick is soo small that for the first six weeks of dating him, his girlfriend thought they were lesbians?"
      },
      {
        "file": "16 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you heard that the guy on your left’s dick is soo small that for the first six weeks of dating him, his girlfriend thought they were lesbians?"
      },
      {
        "file": "17 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that a Judge once put you on house arrest and you couldn’t go more then a hundred feet from your front door, but you shook it off and still hit every bar in the neighborhood, because your smart ass lives in a mobile home?"
      },
      {
        "file": "18 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that a Judge once put you on house arrest and you couldn’t go more then a hundred feet from your front door, but you shook it off and still hit every bar in the neighborhood, because your smart ass lives in a mobile home?"
      },
      {
        "file": "19 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that your breath is sooo bad that people won’t even answer your voice mail?"
      },
      {
        "file": "2 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the guy on your rights penis is so small that when he pees his balls probably get wet?"
      },
      {
        "file": "20 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that your breath is sooo bad that people won’t even answer your voice mail?"
      },
      {
        "file": "21 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you believe the worlds greatest invention is the human ass?"
      },
      {
        "file": "22 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you believe the worlds greatest invention is the human ass?"
      },
      {
        "file": "23 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that everyone thinks you’re cheap but the truth is your saving for breast augmentation?"
      },
      {
        "file": "24 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that everyone thinks you’re cheap but the truth is your saving for breast augmentation?"
      },
      {
        "file": "25 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that when you eat Latin food and fart, it smells like Chinese food?"
      },
      {
        "file": "26 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that when you eat Latin food and fart, it smells like Chinese food?"
      },
      {
        "file": "27 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that your breath smells like you had rotten eggs, nasty hash browns and a small bowl of buttholes for breakfast?"
      },
      {
        "file": "28 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that your breath smells like you had rotten eggs, nasty hash browns and a small bowl of buttholes for breakfast?"
      },
      {
        "file": "29 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that your breath smells like you had rotten eggs, nasty hash browns and a small bowl of buttholes for breakfast?"
      },
      {
        "file": "3 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the guy on your right never wears condoms because they’re too loose and keep slipping off?"
      },
      {
        "file": "30 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you once got busted sucking air while watching a porno in 3D?"
      },
      {
        "file": "31 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that you once got busted sucking air while watching a porno in 3D?"
      },
      {
        "file": "32 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that if during sex a guy wants a finger up his ass he is gay?"
      },
      {
        "file": "33 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that if during sex a guy wants a finger up his ass he is gay?"
      },
      {
        "file": "34 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Everyone calls you “The Flammin Anus”,because you’re a). a porn star b). an exotic circus clown or c). You once caught your asshole on fire!"
      },
      {
        "file": "35 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Everyone calls you “The Flammin Anus”,because you’re a). a porn star b). an exotic circus clown or c). You once caught your asshole on fire!"
      },
      {
        "file": "36 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Fact or fiction. You could suck a dick in the dark and tell what part of the world the owners from."
      },
      {
        "file": "37 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Fact or fiction. You could suck a dick in the dark and tell what part of the world the owners from."
      },
      {
        "file": "38 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. You can’t figure out why the player on your left, is always broke and short on cash, because they’re always working, for instance they give hand jobs, blow jobs and rim jobs, but you’re starting to think that they work for free! Is this a fact or fiction?"
      },
      {
        "file": "39 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. You can’t figure out why the player on your left, is always broke and short on cash, because they’re always working, for instance they give hand jobs, blow jobs and rim jobs, but you’re starting to think that they work for free! Is this a fact or fiction?"
      },
      {
        "file": "4 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the guy on your right never wears condoms because they’re too loose and keep slipping off?"
      },
      {
        "file": "40 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Fact or fiction. You could tell what part of the world a woman’s from with a quick and simple lick across her clit!"
      },
      {
        "file": "41 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Fact or fiction. You could tell what part of the world a woman’s from with a quick and simple lick across her clit!"
      },
      {
        "file": "5 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the dude on your right doesn’t worry about getting anyone pregnant, cause his dick is so skinny that when he nuts it chokes the cum?"
      },
      {
        "file": "6 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the dude on your right doesn’t worry about getting anyone pregnant, cause his dick is so skinny that when he nuts it chokes the cum?"
      },
      {
        "file": "7 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the guy on your lefts dick is so short that when he pays for a hand job he gets 50% off?"
      },
      {
        "file": "8 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the guy on your lefts dick is so short that when he pays for a hand job he gets 50% off?"
      },
      {
        "file": "9 True or False, Fact or Fiction Complete.MP3",
        "transcript": "For 1 drink. Is it true or false that the dude on your right’s thing is so thin that he gets two kinds of pussy with every woman, the left side of the pussy or the right side or the pussy?"
      }
    ]
  },
  {
    "title": "Acting Out",
    "level": "Medium",
    "tracks": [
      {
        "file": "1 Acting Out.MP3",
        "transcript": "Act out you’re a proctologist, that’s a doctor who examines butt holes."
      },
      {
        "file": "10 Acting Out.MP3",
        "transcript": "Act out losing your virginity."
      },
      {
        "file": "11 Acting Out.MP3",
        "transcript": "Act out you’re participating in an orgy."
      },
      {
        "file": "12 Acting Out.MP3",
        "transcript": "Act out you’re an inspector at a dildo factory."
      },
      {
        "file": "13 Acting Out.MP3",
        "transcript": "Act out you’re stuck outside without an umbrella and its raining dicks."
      },
      {
        "file": "14 Acting Out.MP3",
        "transcript": "Act out your birth."
      },
      {
        "file": "15 Acting Out.MP3",
        "transcript": "Act out you’re having sex with a little person (AKA midget)."
      },
      {
        "file": "16 Acting Out.MP3",
        "transcript": "Act out you’re walking down a dark alley when all of the sudden you’re confronted by a horny and relentless six foot vagina."
      },
      {
        "file": "17 Acting Out.MP3",
        "transcript": "Act out you’re walking down a dark alley when all of the sudden you’re confronted by a horny and relentless six foot uncircumcised erect cock."
      },
      {
        "file": "18 Acting Out.MP3",
        "transcript": "Act out you caught yourself in a zipper."
      },
      {
        "file": "19 Acting out.MP3",
        "transcript": "Act out you’re having sex at the North Pole."
      },
      {
        "file": "2 Acting Out.MP3",
        "transcript": "Act out you’re a gynecologist, that’s a doctor who examines pussy."
      },
      {
        "file": "20 Acting Out.MP3",
        "transcript": "Act out you’re having dinner when suddenly under the table someone starts licking your privates and you cum."
      },
      {
        "file": "21 Acting Out.MP3",
        "transcript": "Act out you’re a stiff dick receiving your first hand job."
      },
      {
        "file": "22 Acting Out.MP3",
        "transcript": "Act out you’re a virgin muff taking on her first boner."
      },
      {
        "file": "23 Acting Out.MP3",
        "transcript": "Act out you’re a rock star."
      },
      {
        "file": "24 Acting Out.MP3",
        "transcript": "Act out you’re a monkey trying to get laid."
      },
      {
        "file": "3 Acting Out.MP3",
        "transcript": "Act out you’re a urologist, that’s a doctor who examines cocks."
      },
      {
        "file": "4 Acting Out.MP3",
        "transcript": "Act out you’re getting it in the butt, on a sneak."
      },
      {
        "file": "5 Acting Out.MP3",
        "transcript": "Act out you’re given head with a climatic ending."
      },
      {
        "file": "6 Acting Out.MP3",
        "transcript": "Act out you’re fucking and drunk."
      },
      {
        "file": "7 Acting Out.MP3",
        "transcript": "Act out your first sexual experience with the same sex."
      },
      {
        "file": "8 Acting Out.MP3",
        "transcript": "Act out you’re for sale and you’re trying to convince someone to purchase you."
      },
      {
        "file": "9 Acting Out.MP3",
        "transcript": "Act out your first orgasm."
      }
    ]
  },
  {
    "title": "Drink N Touch",
    "level": "Medium",
    "tracks": [
      {
        "file": "1 Drink N Touch Complete.MP3",
        "transcript": "While you drink one, get a quick feel on any player outside of their clothes unless they happen to be naked, in which case it’ll be even more fun! If they refuse they must drink two drinks."
      },
      {
        "file": "10 Drink N Touch Complete.MP3",
        "transcript": "You drink 2 drinks or massage any player’s crotch while they have a drink. If they refuse they drink 3 drinks."
      },
      {
        "file": "11 Drink N Touch Complete.MP3",
        "transcript": "Kiss any player anywhere on their body or drink 2, you cannot remove or alter clothing, of course if they happen to be naked, even more fun. If they refuse they must drink 3."
      },
      {
        "file": "12 Drink N Touch Complete.MP3",
        "transcript": "You drink 3 drinks or make any player put their face in your crotch while you drink 2, if they refuse they must drink 2."
      },
      {
        "file": "13 Drink N Touch Complete.MP3",
        "transcript": "You drink 3 drinks or put one hand inside another player’s clothes anywhere, unless they happen to be naked, in which case it’ll be even more fun, and rub them while you drink 1, if they refuse they must drink 2."
      },
      {
        "file": "14 Drink N Touch Complete.MP3",
        "transcript": "You drink 3 drinks or put one hand inside another player’s top clothes, unless they happen to be naked, in which case it’ll be even more fun, and rub them while you drink one. If they refuse they must drink 2."
      },
      {
        "file": "15 Drink N Touch Complete.MP3",
        "transcript": "You drink 3 drinks or put one hand inside another player’s bottom clothes, unless they happen to be naked, in which case it’ll be even more fun, and rub them while you drink one. If they refuse they must drink 2."
      },
      {
        "file": "16 Drink N Touch Complete.MP3",
        "transcript": "Kiss any player’s bare ass cheek or drink 3 drinks. If they refuse they must drink 2."
      },
      {
        "file": "17 Drink N Touch Complete.MP3",
        "transcript": "Lick any player’s bare ass cheek or drink 3 drinks. If they refuse they must drink 2."
      },
      {
        "file": "18 Drink N Touch Complete.MP3",
        "transcript": "You must drink 3 drinks or drink 1 drink while massaging any player’s bare ass cheek. If they refuse they must drink 3."
      },
      {
        "file": "19 Drink N Touch Complete.MP3",
        "transcript": "Smack lightly any player’s bare ass cheeks while they drink 3 drinks. If they refuse they must drink 4."
      },
      {
        "file": "2 Drink N Touch Complete.MP3",
        "transcript": "You drink 3 drinks or grab another player’s crotch and hold it until that player finishes 1 drink. If they refuse they must drink 3 drinks."
      },
      {
        "file": "20 Drink N Touch Complete.MP3",
        "transcript": "Smack lightly any player’s ass while they drink 2 drinks. If they refuse they must drink 4."
      },
      {
        "file": "21 Drink N Touch Complete.MP3",
        "transcript": "Kiss any player while all the other players drink 2 drinks. If the chosen player refuses the kiss they must drink 4 alone."
      },
      {
        "file": "22 Drink N Touch Complete.MP3",
        "transcript": "Place your face in any player’s crotch and hum while they drink 3 quick drinks. If the chosen player refuses they must drink 5 drinks alone."
      },
      {
        "file": "23 Drink N Touch Complete.MP3",
        "transcript": "Kiss any player’s crotch or drink 3 drinks. If they refuse they must drink 2. "
      },
      {
        "file": "24 Drink N Touch Complete.MP3",
        "transcript": "Lick any player’s forehead or drink 2 drinks. If they refuse they must drink 2."
      },
      {
        "file": "25 Drink N Touch Complete.MP3",
        "transcript": "Lick any player’s neck or drink 3 drinks. If they refuse they must drink 2."
      },
      {
        "file": "3 Drink N Touch Complete.MP3",
        "transcript": "You drink 3 drinks or hide a finger in any player while they drink one. If they refuse they must drink half a beer. "
      },
      {
        "file": "4 Drink N Touch Complete.MP3",
        "transcript": "You drink 3 drinks or kiss any player anywhere they have exposed skin. If they refuse they must drink one."
      },
      {
        "file": "5 Drink N Touch Complete.MP3",
        "transcript": "You drink 3 drinks or lick any player anywhere they have exposed skin, if they refuse they must drink 3."
      },
      {
        "file": "6 Drink N Touch Complete.MP3",
        "transcript": "Grab any player’s ass and hold it while they drink until I say stop!"
      },
      {
        "file": "7 Drink N Touch Complete.MP3",
        "transcript": "You drink 2 drinks or Grab any set of tittes (not your own) and massage while they have a drink. If they refuse they must drink 2 drinks."
      },
      {
        "file": "8 Drink N Touch Complete.MP3",
        "transcript": "Stick your hands in your underwear (if you’re wearing underwear) and play with yourself while you drink two drinks, or refuse and drink four drinks."
      },
      {
        "file": "9 Drink N Touch Complete.MP3",
        "transcript": "You have a drink or stick your tongue in any player; if they refuse they must drink 2 drinks."
      }
    ]
  },
  {
    "title": "Drinking 1, 2 or 3",
    "level": "Medium",
    "tracks": [
      {
        "file": "1 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever had sweaty balls on your chin? "
      },
      {
        "file": "10 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever been so drunk that you let a complete stranger give you head?"
      },
      {
        "file": "11 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever swiped money off the bar that wasn’t yours?"
      },
      {
        "file": "12 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever lied about a friend just to help get’em laid?"
      },
      {
        "file": "13 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever taken a really bad dump in a public restroom?"
      },
      {
        "file": "14 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever been caught having sex?"
      },
      {
        "file": "15 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever been caught masturbating?"
      },
      {
        "file": "16 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever scratched or cut someone with your teeth or a tooth?"
      },
      {
        "file": "17 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever had sex while one of you was bleeding?"
      },
      {
        "file": "18 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever ridden a bicycle without a seat?"
      },
      {
        "file": "19 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Has your face ever smelt like someone’s crotch?"
      },
      {
        "file": "2 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever licked a sweaty vagina?"
      },
      {
        "file": "20 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever gone roller skating, naked?"
      },
      {
        "file": "21 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever played with, a juggler’s balls?"
      },
      {
        "file": "22 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever won a lottery?"
      },
      {
        "file": "23 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever bought a round of beers?"
      },
      {
        "file": "24 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever seen Uranus through a telescope?"
      },
      {
        "file": "25 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever fell down a flight of stairs, while you were horny?"
      },
      {
        "file": "26 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever masturbated while thinking about a relative?"
      },
      {
        "file": "27 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever ran through the house naked, with scissors in both hands?"
      },
      {
        "file": "28 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever dreamt that you were the opposite sex?"
      },
      {
        "file": "29 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever ran through the rain wearing only a metal cap?"
      },
      {
        "file": "3 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Has anyone ever slipped a finger up your ass?"
      },
      {
        "file": "30 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever referred to a loved one as, the cunt or that little prick?"
      },
      {
        "file": "31 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever used GPS to get home from the bar?"
      },
      {
        "file": "32 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever been pissed off at someone but fucked them anyway?"
      },
      {
        "file": "33 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever thought about someone you didn’t like, while you were cumming?"
      },
      {
        "file": "34 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever worn someone else’s underwear?"
      },
      {
        "file": "35 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever used the toilet and for whatever reason, didn’t wipe?"
      },
      {
        "file": "36 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Has anyone ever caused you pain in your ass?"
      },
      {
        "file": "37 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever given head with gum in your mouth?"
      },
      {
        "file": "38 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever skipped in a thunderstorm while wearing aluminum foil underwear?"
      },
      {
        "file": "39 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever received head in the rain by someone wearing braces?"
      },
      {
        "file": "4 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever choked on something that came from someone else’s body?"
      },
      {
        "file": "40 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever had your nose so close to someone’s ass that you could feel the heat?"
      },
      {
        "file": "41 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever driven a car naked?"
      },
      {
        "file": "42 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever sat on someone’s face right after being at an all you can eat buffet?"
      },
      {
        "file": "43 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever pinched or stretched a foreskin?"
      },
      {
        "file": "44 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever pinched or stretched vagina lips?"
      },
      {
        "file": "45 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever been soo close to a set of balls that you had no peripheral vision?"
      },
      {
        "file": "46 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Has anything shipped from overseas wound up in your ass?"
      },
      {
        "file": "47 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever choked on a cock, and you weren’t on a farm at the time?"
      },
      {
        "file": "5 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever had an orgasm with your clothes still on?"
      },
      {
        "file": "6 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever pretended to be drunk so that someone would lay you?"
      },
      {
        "file": "7 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever had someone’s body fluids stick to your face?"
      },
      {
        "file": "8 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever paid for a round of shots?"
      },
      {
        "file": "9 Drinking 1, 2 or 3 W Effects and Music.MP3",
        "transcript": "Have you ever been so drunk that you gave head to a complete stranger?"
      }
    ]
  },
  {
    "title": "Have You Ever Licked",
    "level": "Medium",
    "tracks": [
      {
        "file": "1 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a bar stool? A bar rag? A bar fly?"
      },
      {
        "file": "10 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a wedding cake? A bride’s pie? A groom’s ding-dong?"
      },
      {
        "file": "11 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, brown jerky? White turkey? Something pink and perky?"
      },
      {
        "file": "12 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, someone cranky? Someone stanky? Someone skanky?"
      },
      {
        "file": "13 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, something slippery? Something sticky or a really short and hairy Hippy?"
      },
      {
        "file": "14 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, an envelope? A cantaloupe? An antelope?"
      },
      {
        "file": "15 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, jam off toes? Lint off a belly button? Pus off a pimple?"
      },
      {
        "file": "16 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, powdered sugar off an ass? Whipped cream off an ass? Crusty shit off an ass?"
      },
      {
        "file": "17 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, nose hair? Ear wax? Head cheese?"
      },
      {
        "file": "18 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a spilled shot off the bar? Some moonshine out of a jar? Some nut off the backseat of a car?"
      },
      {
        "file": "19 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, someone while sleeping next to them? While you were drunk next to them? While you were fucking next to them?"
      },
      {
        "file": "2 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a bicycle seat? A piece of raw meat? A cute guy named Pete?"
      },
      {
        "file": "20 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, someone’s bruise? Someone’s cut? Someone’s rash?"
      },
      {
        "file": "21 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a little snow? A little ice? A little penguin?"
      },
      {
        "file": "22 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, the back of a friend’s neck? Inside of a friend’s armpit? All around a friend’s ass crack?"
      },
      {
        "file": "23 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a wooden banister? Marble stairs? A leather chair?"
      },
      {
        "file": "24 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a gas station toilet seat? A restroom doorknob? The handle on the urinal?"
      },
      {
        "file": "25 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a stranger’s tongue? A stranger’s nipples? A stranger’s spouse?"
      },
      {
        "file": "26 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, something out of someone? Something spilled on someone? Something on someone from someone else?"
      },
      {
        "file": "27 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a newspaper? Sandpaper? Toilet paper?"
      },
      {
        "file": "28 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a kitten’s forehead? a duck’s bill, a farmer’s rooster?"
      },
      {
        "file": "29 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a coffee cake? A urinal cake? A midget named Jake?"
      },
      {
        "file": "3 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, something a little gritty? Something a little shitty? Someone’s a little titty?"
      },
      {
        "file": "30 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, bitters off a big booty? Sugar off a real cutie? Salt off a guy named Rudy?"
      },
      {
        "file": "31 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a slightly used condom? A slightly used piece of toilet paper? A slightly used piece of ass?"
      },
      {
        "file": "32 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a midget’s mole? A midget’s toe? A midget’s booty hole?"
      },
      {
        "file": "33 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, two swinging balls? Three ass cracks in a roll? The four nipples on a set of twins?"
      },
      {
        "file": "34 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a pizza pie? An apple pie? A girl’s pie?"
      },
      {
        "file": "35 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a stranger’s face? A stranger’s chest? A stranger’s breast?"
      },
      {
        "file": "36 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a sweet ass crack? A bitter clit? A salty dick?"
      },
      {
        "file": "37 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, someone’s toes? The sole of their foot? A shoe that they wore?"
      },
      {
        "file": "38 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked someone, standing? Lying down? Bent over?"
      },
      {
        "file": "39 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked someone, In a crowd? In an alley? In a crowded alley?"
      },
      {
        "file": "4 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a diamond ring? A cock ring? A utering?"
      },
      {
        "file": "40 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked someone, while they were jogging? While they were on the phone? While they were playing with themselves?"
      },
      {
        "file": "41 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a coin? A dollar bill? A credit card?"
      },
      {
        "file": "42 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a little bug? A medium sized ant? A large uncle?"
      },
      {
        "file": "43 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, somebodies Mommy? Somebodies Daddy? Somebodies Mommy’s Daddy?"
      },
      {
        "file": "44 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked a perky titty?  A perky butt? A homeless person, in a rut?"
      },
      {
        "file": "45 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked someone for money?"
      },
      {
        "file": "5 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, someone’s belly? Something smelly? A person named Kelly?"
      },
      {
        "file": "6 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a rusty nail? A little snail? A monkey’s tail?"
      },
      {
        "file": "7 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a friend’s tail? Someone just out of jail? My vagina? "
      },
      {
        "file": "8 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, a stained cotton panel? A dirty vagina? A stinky booty?"
      },
      {
        "file": "9 Have You Ever Licked Complete.MP3",
        "transcript": "Have you ever licked, between someone’s toes? The back of someone’s balls? The inside of someone’s snatch?"
      }
    ]
  },
  {
    "title": "That's Soo Rude",
    "level": "Medium",
    "tracks": [
      {
        "file": "1 Thats Soo Rude Complete.MP3",
        "transcript": "Has your anus ever been slightly stretched because you got your ass kicked by someone wearing pointy shoes? Has a midget ever kicked you in the ass soo hard that he got stuck?"
      },
      {
        "file": "10 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been kicked in the ass, while picking up change? Did you ever get your ass kicked so well, that for two weeks you didn’t complain about anything?"
      },
      {
        "file": "11 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been kicked in the ass soo hard that you could identify the brand of shoe they were wearing? Have you ever been multi-slapped?"
      },
      {
        "file": "12 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever slapped soo fast, that you didn’t even get a chance to start talking shit? Have you ever been punched in the head while you were enjoying a beverage?"
      },
      {
        "file": "13 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever punched in the head while you were trying on hats? Have you ever been slapped in the head, while giving head?"
      },
      {
        "file": "14 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever punched in the teeth at the dentist office? Were you ever punched in the eye while shopping for glasses?"
      },
      {
        "file": "15 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched in the face soo hard, that the next day nobody recognized you? Have you ever been kicked in the ass so hard, that in your underwear you had a footprint shaped shit stain?"
      },
      {
        "file": "16 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched in the head soo hard by someone, that you immediately decided not to like them? Were you ever kicked in the ass by a proctologist?"
      },
      {
        "file": "17 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever got kicked in the ass by a soccer player? Were you ever cracked in the middle of making a point?"
      },
      {
        "file": "18 Thats Soo Rude Complete.MP3",
        "transcript": "Did you ever get your ass kicked in school? Have you ever got your ass kick in the middle of the street by a crossing guard?"
      },
      {
        "file": "19 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched in the head right after you were told to shut-up? Were you ever slapped into sucking on to something?"
      },
      {
        "file": "2 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever slapped in the head while you were getting a haircut? Have you ever been slapped while you were taking a shit?"
      },
      {
        "file": "20 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched in the head soo hard, that for two weeks you couldn’t even do simple math? Ever get kicked in the ass soo hard that for two weeks you couldn’t shit right?"
      },
      {
        "file": "21 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever got slapped at the zoo while standing under a giraffe? Were you ever abducted by aliens and got your ass kicked in space?"
      },
      {
        "file": "22 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever slapped in the ass while taking a shower? Have you ever hit someone in the head with a household appliance?"
      },
      {
        "file": "23 Thats Soo Rude Complete.MP3",
        "transcript": "Has a good slap ever helped you to see someone else’s point of view? Have you ever got your ass kicked by someone with no shoes on and you could swear you felt a toe go up your ass?"
      },
      {
        "file": "24 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been quickly slapped three times in a row? Have you ever been kicked so hard in the ass, that your cell phone auto called you an ambulance?"
      },
      {
        "file": "25 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever had sex while you were asleep? Maybe you just don’t know! Maybe another player can vouch for you! Were you ever slapped while getting fucked?"
      },
      {
        "file": "26 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever caught a crack that instantly changed your hair-doo? Has anyone ever used their foot to give you a wedgie?"
      },
      {
        "file": "27 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been licked on, right after being slapped really hard three times in a roll? Have you ever been punched in the nose so hard that for two weeks you couldn’t even smell your own farts?"
      },
      {
        "file": "28 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever bit a piece of body off of someone? Ever kick someone’s ass so good that the next day you had to wash your shoes?"
      },
      {
        "file": "29 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever slapped in the head while shopping for aspirin? Have you ever hit someone with some type of object?"
      },
      {
        "file": "3 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched in the stomach so hard that it made you fart? Have you ever been slapped for being stupid?"
      },
      {
        "file": "30 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever got your ass kicked so well, that for two weeks you went around apologizing to everyone? Have you ever been punched in the head for no apparent reason?"
      },
      {
        "file": "31 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever kicked in the ass by someone with only one leg? Did you ever get a splinter up your ass because the guy who kicked it had a peg leg?"
      },
      {
        "file": "32 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever punched in the head soo hard that you felt your opinion didn’t matter? Were you ever been punched in the head while you were saying, I don’t want to fight?"
      },
      {
        "file": "33 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever kicked in the ass while you were shopping for shoes? Have you ever been punched in the teeth while shopping for a toothbrush?"
      },
      {
        "file": "34 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever punched in the head in the dark? Anybody ever kick you in the ass so hard, that it made a shit stain on the outside of your pants?"
      },
      {
        "file": "35 Thats Soo Rude Complete.MP3",
        "transcript": "Has a quick slap ever helped you make a snappy decision? You ever get punched in the head so hard that when you opened your eyes, you were in a whole different place?"
      },
      {
        "file": "36 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever punched in the ear right after you were told to listen? Were you ever kicked in the ass by a shoe salesman?"
      },
      {
        "file": "37 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever got your ass kicked while you were naked? Have you ever been slapped so hard, that you instantly forgot what you were complaining about? "
      },
      {
        "file": "38 Thats Soo Rude Complete.MP3",
        "transcript": "Did it ever take a punch in the eyes to help you see things more clearly? Have you ever been punched in the head so hard that you forgot who you were?"
      },
      {
        "file": "39 Thats Soo Rude Complete.MP3",
        "transcript": "Did you ever get punched in the eyes so hard, that for two weeks you couldn’t even blink? Have you ever been kicked in the ass so hard that your socks slid down? "
      },
      {
        "file": "4 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever kicked in the ass by someone with a really big foot? Were you ever in a fight with multiple midgets?"
      },
      {
        "file": "40 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been kick in the groin so hard that you had an out of body experience? Have you ever picked a fight with someone just so you could feel them up?"
      },
      {
        "file": "41 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched in the head so hard that a little bit of ear wax came out? Have you ever been slapped and instantly, you seen their point of view? "
      },
      {
        "file": "42 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever got your ass kicked while you were grocery shopping? Were you ever beat up at the circus by a clown?"
      },
      {
        "file": "43 Thats Soo Rude Complete.MP3",
        "transcript": "Did you ever swallow some snots after being punched in the nose? Did you ever shit out toenails, because the night before you got your ass kicked by someone who was bare footed? "
      },
      {
        "file": "44 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched in the face because the person in front of you ducked? Has a kung fu master ever kicked you in the face?"
      },
      {
        "file": "45 Thats Soo Rude Complete.MP3",
        "transcript": "You ever get your ass kick so well that for two weeks, you were kinda quiet? You ever kicked someone’s ass so bad that you had to take the next day off of work to recuperate?"
      },
      {
        "file": "46 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever kicked someone in the ass so hard that for two weeks you limped? Have you ever got your ass kicked by a chick?"
      },
      {
        "file": "47 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been kicked in the ass so hard, that later you shit out a footprint? Were you ever slapped while playing Hop-Scotch?"
      },
      {
        "file": "48 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever thrown food at someone? Have you ever had or given a black eye?"
      },
      {
        "file": "49 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched so hard in the stomach that you let out a little bit of shit? Do you catch cracks on a regular basis?"
      },
      {
        "file": "5 Thats Soo Rude Complete.MP3",
        "transcript": "While yelling at you in a foreign language, has anyone ever punched you in the head soo hard that you understood what they were saying? Have you ever got shit on your shoes because you kicked someone’s ass real good?"
      },
      {
        "file": "50 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever in a fight with somebody and thought this might be a good opportunity to pinch their nipples? Have you ever kicked somebody in the ass with steel toe boots?"
      },
      {
        "file": "51 Thats Soo Rude Complete.MP3",
        "transcript": "Did you ever get punched in the head while you were apologizing? Have you ever been punched in the stomach so hard that you had to swallow your tonsils back into place?"
      },
      {
        "file": "52 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been slapped so hard that a little bit of pee came out? Have you ever got punched in the head so hard that you were willing to take the blame, for anything?"
      },
      {
        "file": "53 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever punched in the head while you were trying on hats? Were you ever been slapped in the face so hard, that you lost control of your eyeballs?"
      },
      {
        "file": "54 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever caught a boot in the ass while you were shopping for boots? Have you ever got kicked in the ass while shopping for a football?"
      },
      {
        "file": "55 Thats Soo Rude Complete.MP3",
        "transcript": "Has anyone ever kicked you in the ass soo hard that your hat fell off? Have you ever been slapped soo good that for two weeks you had a stutter?"
      },
      {
        "file": "56 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever punched in the back of the head soo hard that your contact lenses popped out? Do you consider kicking someone’s ass, exercise"
      },
      {
        "file": "57 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever in a fight with more than one person? Have you ever got your ass kicked while you were shopping for first aid supplies? "
      },
      {
        "file": "58 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been slapped soo hard that for two weeks you couldn’t taste anything? Have you ever been kicked in the ass so hard change fell out of your pocket?"
      },
      {
        "file": "59 Thats Soo Rude Complete.MP3",
        "transcript": "Has anyone ever kicked sand up your ass while you were on the beach? Were you ever punched in the head at an anti-violence rally?"
      },
      {
        "file": "6 Thats Soo Rude Complete.MP3",
        "transcript": "You ever got kicked in the ass so hard that the next day you were taller? Have you ever caught a random slap?"
      },
      {
        "file": "60 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever given someone a crack because they were annoying the fuck out of you? Have you ever got punched in the head while you were singing Karaoke? "
      },
      {
        "file": "61 Thats Soo Rude Complete.MP3",
        "transcript": "Did you ever give someone a quick ass whooping, during a marathon? Have you ever been slapped by two people at the same time?"
      },
      {
        "file": "62 Thats Soo Rude Complete.MP3",
        "transcript": "You ever get slapped soo hard that you were completely ex-foliated? Have you ever caught a crack in the head right after you fixed your hair?"
      },
      {
        "file": "63 Thats Soo Rude Complete.MP3",
        "transcript": "Did you ever get your ass kicked by someone from another planet? Were you ever slapped in the face with a penis."
      },
      {
        "file": "64 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever cracked so efficiently that you lost your train of thought? Were you ever slapped so hard that you gave a full confession about something you knew nothing about?"
      },
      {
        "file": "65 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever been punched in the head while shopping for socks? Have you ever kicked someone’s ass so good, that when you were through your shoes were polished?"
      },
      {
        "file": "66 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever caught a slap that was soo fast you didn’t even see it coming? Have you ever been punched in the head in front of your mommy?"
      },
      {
        "file": "67 Thats Soo Rude Complete.MP3",
        "transcript": "Were you ever in a fight while you were intoxicated? Has anybody ever stuck up for you in a fight and kicked that person’s ass on your behalf?"
      },
      {
        "file": "68 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever slapped someone whose hands were full? Have you ever been in a fight and ripped off their bra, exposing those titties? "
      },
      {
        "file": "69 Thats Soo Rude Complete.MP3",
        "transcript": "Did you ever have a fist fight with an elderly person and you got your ass kicked? You believe in aliens because you got your ass kicked by one?"
      },
      {
        "file": "7 Thats Soo Rude Complete.MP3",
        "transcript": "You ever get your ass kicked while they were speaking to you in a foreign language and you didn’t even know what that ass kicking was for? Ever had a fight with someone and later on wound up fucking them?"
      },
      {
        "file": "70 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever got your ass kicked really hard in front of a bunch of people and you try to pretend it didn’t happen by skipping away!"
      },
      {
        "file": "8 Thats Soo Rude Complete.MP3",
        "transcript": "You ever get punched in the face so hard that you forgot you were fighting? Have you ever been smacked in the ass so hard that you almost came?"
      },
      {
        "file": "9 Thats Soo Rude Complete.MP3",
        "transcript": "Have you ever slapped someone so hard that your hand hurt, which made you slap them again? Did you ever kick the wrong person’s ass?"
      }
    ]
  },
  {
    "title": "Virtual Drink Cards",
    "level": "Medium",
    "tracks": [
      {
        "file": "1 Virtual Drink Cards Complete.MP3",
        "transcript": "You must not blink until the clock stops, did it, good, pick someone to have one. Failed for any reason you have one."
      },
      {
        "file": "10 Virtual Drink Cards Complete.MP3",
        "transcript": "Congratulations, the local police department has finally recovered your stolen asshole. Drink 2 for losing it in the first place."
      },
      {
        "file": "11 Virtual Drink Cards Complete.MP3",
        "transcript": "Congratulations, you were voted the prettiest mouth by the inmates of cell block C. have a drink. "
      },
      {
        "file": "12 Virtual Drink Cards Complete.MP3",
        "transcript": "You got busted collecting dicks in your basement, everyone who currently has their dick has a drink with you."
      },
      {
        "file": "13 Virtual Drink Cards Complete.MP3",
        "transcript": "Drink 3 drinks in tribute to Elvis Presley."
      },
      {
        "file": "14 Virtual Drink Cards Complete.MP3",
        "transcript": "Get the person on your rights next 2 refills."
      },
      {
        "file": "15 Virtual Drink Cards Complete.MP3",
        "transcript": "You’re easy when you’re drunk, so have 2 drinks punk."
      },
      {
        "file": "16 Virtual Drink Cards Complete.MP3",
        "transcript": "Your lover passes out at the bar so you barrow some cash and get everyone’s next round of drinks."
      },
      {
        "file": "17 Virtual Drink Cards Complete.MP3",
        "transcript": "You’re elected designated driver, so you must drink half a beer on a sneak, quickly now!"
      },
      {
        "file": "18 Virtual Drink Cards Complete.MP3",
        "transcript": "You gave head earlier today and are having some difficulties with stubborn pubic hairs, have 3 drinks to help get’em down!"
      },
      {
        "file": "19 Virtual Drink Cards Complete.MP3",
        "transcript": "Everyone stand and salute The Armed Forces, last one has a drink."
      },
      {
        "file": "2 Virtual Drink Cards Complete.MP3",
        "transcript": "You chipped a tooth on a cock ring so drink 1 to kill the pain."
      },
      {
        "file": "20 Virtual Drink Cards Complete.MP3",
        "transcript": "You pick any player to drink a whole beer."
      },
      {
        "file": "21 Virtual Drink Cards Complete.MP3",
        "transcript": "Community Service Appreciation. Get a beer for anyone who holds or has applied for a city job. "
      },
      {
        "file": "22 Virtual Drink Cards Complete.MP3",
        "transcript": "You failed math on your G.E.D. test, so drink 3.75 ounces’ dumb ass."
      },
      {
        "file": "23 Virtual Drink Cards Complete.MP3",
        "transcript": "You were asked to head the gay and lesbian day parade, have a drink for finally coming out."
      },
      {
        "file": "24 Virtual Drink Cards Complete.MP3",
        "transcript": "Drink until the person on your right says stop or the timer runs out…ready, start."
      },
      {
        "file": "25 Virtual Drink Cards Complete.MP3",
        "transcript": "You’re in charge of the boy’s club beer garden, pick 2 players to drink 2 drinks each."
      },
      {
        "file": "26 Virtual Drink Cards Complete.MP3",
        "transcript": "Community Service. Your parent is an elected official, but you get drunk and beat on people anyways! Collect everyone’s trash and throw it out."
      },
      {
        "file": "27 Virtual Drink Cards Complete.MP3",
        "transcript": "You just sucked a dirty crotch, gargle and drink the rest of your drink."
      },
      {
        "file": "28 Virtual Drink Cards Complete.MP3",
        "transcript": "Squad car pulls you over for no valid reason, meanwhile your beer gets piss warm. You sue and win using profiling as the motive. Everyone including you, drinks to your balls."
      },
      {
        "file": "29 Virtual Drink Cards Complete.MP3",
        "transcript": "You said you would be in by midnight, its 4 am, you have another drink with 2 other players."
      },
      {
        "file": "3 Virtual Drink Cards Complete.MP3",
        "transcript": "You cut your tongue on a rusty clit piercing, so drink one to kill the infection."
      },
      {
        "file": "30 Virtual Drink Cards Complete.MP3",
        "transcript": "Just had a new baby, receive 2 drinks from every player."
      },
      {
        "file": "31 Virtual Drink Cards Complete.MP3",
        "transcript": "Thai Bo pays off, everyone fears you and has a drink to your tight ass!"
      },
      {
        "file": "32 Virtual Drink Cards Complete.MP3",
        "transcript": "Community Service, you must put all empties in the trash."
      },
      {
        "file": "33 Virtual Drink Cards Complete.MP3",
        "transcript": "You’ve won on the football squares; everyone owes you 1 drink!"
      },
      {
        "file": "34 Virtual Drink Cards Complete.MP3",
        "transcript": "You just got laid by the bartender, everyone has two free drinks with you."
      },
      {
        "file": "35 Virtual Drink Cards Complete.MP3",
        "transcript": "Get the person on your left’s next drink, bitch! "
      },
      {
        "file": "36 Virtual Drink Cards Complete.MP3",
        "transcript": "You just came out of the closet, have 2 drinks with all the same sex."
      },
      {
        "file": "37 Virtual Drink Cards Complete.MP3",
        "transcript": "Get the person on your left’s next 2 drinks."
      },
      {
        "file": "38 Virtual Drink Cards Complete.MP3",
        "transcript": "Take a pee break at your pleasure. Remember to pick any player to cover your game player responsibilities while you’re gone."
      },
      {
        "file": "39 Virtual Drink Cards Complete.MP3",
        "transcript": "Dumbass hungover bank teller gives you an extra 100 bucks. You get everyone’s next beer."
      },
      {
        "file": "4 Virtual Drink Cards Complete.MP3",
        "transcript": "During a recent event the host ran out of chairs, so you sat on a friends face, consume a drink as you relax."
      },
      {
        "file": "40 Virtual Drink Cards Complete.MP3",
        "transcript": "Community service. Because you went to Graceland and threw up on Elvis’s Blue Suede Shoes, pick up all the trash."
      },
      {
        "file": "41 Virtual Drink Cards Complete.MP3",
        "transcript": "Celebrate your first head job, drink 2 drinks to get the taste out of your mouth."
      },
      {
        "file": "42 Virtual Drink Cards Complete.MP3",
        "transcript": "You Suck! Have 2 drinks."
      },
      {
        "file": "43 Virtual Drink Cards Complete.MP3",
        "transcript": "You saved a man from choking, your medical G.E.D. comes in handy! Everyone but you has 2 drinks honoring your heroism."
      },
      {
        "file": "44 Virtual Drink Cards Complete.MP3",
        "transcript": "Busted blowing the gym teacher, AGAIN, suspended from school, so you party down the rest of your beer."
      },
      {
        "file": "45 Virtual Drink Cards Complete.MP3",
        "transcript": "Arrested while partying with minors, drink 2 drinks to the memories and to clear out the coal dust."
      },
      {
        "file": "46 Virtual Drink Cards Complete.MP3",
        "transcript": "Drink until the person on your left says stop or the timer runs out."
      },
      {
        "file": "5 Virtual Drink Cards Complete.MP3",
        "transcript": "Your close friend was upset after being diagnosed with leaky anus syndrome, but you quickly offered a solution, your thumb. So have a celebratory drink with that player, without using your thumbs."
      },
      {
        "file": "6 Virtual Drink Cards Complete.MP3",
        "transcript": "Congratulations, your ass as been voted the finest in the state, everyone including you, has a cheerful drink."
      },
      {
        "file": "7 Virtual Drink Cards Complete.MP3",
        "transcript": "During this year’s annual food festival your crotch was voted the tastiest in the state. Everyone including you, has a cheerful drink. "
      },
      {
        "file": "8 Virtual Drink Cards Complete.MP3",
        "transcript": "Awwww shit, giving all that head has left you with a severe case of dry throat, so have 3 quick drinks."
      },
      {
        "file": "9 Virtual Drink Cards Complete.MP3",
        "transcript": "Congratulations, you opened your very own bar, get everyone’s next refill."
      }
    ]
  }
];

export default CATEGORIES;