import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(6, 0, 3),
        textAlign: "center",
    },
    lightBulb: {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1),
    },
}));

export default function AgeChecker(props) {
    const { setAge } = props;
    const classes = useStyles();
    const [month, setMonth] = React.useState("");
    const [day, setDay] = React.useState("");
    const [year, setYear] = React.useState("");
    const formHandler = () => {
        const today = new Date();
        let age = today.getFullYear() - year;
        const m = today.getMonth() - month;
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
         age--;
        }
        if (age < 18) {
            window.location.href = 'https://www.crazygames.com/';
        } else {
            setAge(age);
        }
    }
    return (
      <Box class={classes.root}>
        Age Verification
        <p>This website is intended for an 18+ audience. Please enter your date of birth to continue.</p>
        <form>
            <input placeholder='month number' value={month} onChange={(e) => setMonth(e.target.value)} />
            <input placeholder='day' value={day} onChange={(e) => setDay(e.target.value)} />
            <input placeholder='year' value={year} onChange={(e) => setYear(e.target.value)} />
            <Button onClick={formHandler}>Submit</Button>
        </form>
      </Box>
    );
  }
  