import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ProTip from './ProTip';
import { Button } from '@material-ui/core';
import GameSetup from './GameSetup';


export default function App() {
  return (
    <Container maxWidth="md">
      <Box xs="12" my="2">
        <Typography variant="h2" component="h1" align="center" style={{fontFamily: "Lobster,Roboto,Arial,sans-serif", color: "#ff8800"}}>
          You Buzzed Yet?
        </Typography>
      </Box>
      <Box xs="12" my="2">
        <GameSetup />
      </Box>
      <Box my={4}>
        <Typography className="copyright" variant="body2" align="center">
          {'Copyright © YouBuzzedYet.com '}
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );
}
