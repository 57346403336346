import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ReactSvgTimer } from 'react-svg-timer';

import Music from './AudioPlayer';

import CATEGORIES from './Categories';
import PLAYERS from './PlayerData';


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function Game(props) {

  const { players, categories } = props;

  const [ gameState, setGameState ] = React.useState(0);
  const [ currentPlayer, setCurrentPlayer ] = React.useState();
  const [ currentCategory, setCurrentCategory ] = React.useState();
  const [ currentTrack, setCurrentTrack ] = React.useState();
  const [ currentTrackTranscript, setCurrentTrackTranscript ] = React.useState();
  const [ trackDuration, setTrackDuration ] = React.useState(0);
  const [ trackFilePath, setTrackFilePath ] = React.useState("");
  const [ trackTranscript, setTrackTranscript ] = React.useState("");
  const [ audioRef ] = React.useState(useRef());

  React.useEffect(async () => {
    switch (gameState) {
      case 0:
        await getCurrentPlayer();
        break
      case 1:
        await playPlayerAudio();
        break
      case 2:
        await getCurrentTrack();
        break
      case 3:
        await startTrackAudio();
        break
      case 4:
        await sleep(2000);
        await setGameState(0);
        break
      default:
        break
    }
  }, [gameState]);


  const getCurrentPlayer = async () => {
    console.log('getCurrentPlayer');
    const randomPlayer = players[Math.floor(Math.random()*players.length)];
    await setCurrentPlayer(randomPlayer);
    await setGameState(1);
  }

  const playPlayerAudio = () => {
    console.log('playPlayerAudio');
    const playerName = PLAYERS[currentPlayer];
    const playerAudioFile = `./assets/audio/game-piece-sounds-with-effects/${playerName}.mp3`;
    console.log(playerAudioFile);
    const audio = new Audio(playerAudioFile);
    audio.loop = false;
    audio.onended = (e) => {
      setGameState(2);
    }
    audio.play();
  }

  const getCurrentTrack = async () => {
    console.log('getCurrentTrack');
    const randomCategoryIndex = Math.floor(Math.random()*categories.length);
    const randomCategoryKey = categories[randomCategoryIndex];
    const randomCategory = CATEGORIES[randomCategoryKey];
    const randomTrackKey = Math.floor(Math.random()*randomCategory.tracks.length);
    const randomTrack = CATEGORIES[randomCategoryKey].tracks[randomTrackKey];

    const trackFilePath = "./assets/audio/tracks/" + randomCategory.level + ' - ' + randomCategory.title + "/" + randomTrack.file;
    const trackTranscript = randomTrack.transcript;

    await setCurrentCategory({
      key: randomCategoryKey,
      title: randomCategory.title,
      level: randomCategory.level,
    });
    await setCurrentTrack(randomTrack.file);
    await setTrackFilePath(trackFilePath);
    await setCurrentTrackTranscript(randomTrack.transcript);
    await setGameState(3);
  }

  const startTrackAudio = async () => {
    console.log('startTrackAudio');
    audioRef.current.src = trackFilePath;
    audioRef.current.loop = false;
    audioRef.current.onended = async (e) => {
      console.log("Player audio ended");
      await setGameState(4);
    }
    audioRef.current.onloadeddata = async (e) => {
      await setTrackDuration(audioRef.current.duration);
    }
    await sleep(100);
    audioRef.current.load();
    await audioRef.current.play();
  }

  if (gameState == 0) {
    return null
  }
  const playerName = PLAYERS[currentPlayer];

  return (
    <Box xs={12} my={3} align="center">
      <img src={"./assets/avatars/" + playerName + ".gif"} className="avatar-img" width="100px" height="auto" style={{ marginRight: "5px", height: "100px", borderRadius: "20px"  }} />
      <Typography align="center" variant="h3" component="h2">
        Player Up - {playerName}
      </Typography>
      {gameState > 2 ? (
        <Box my={2}>
          <Typography variant="h4" component="h2" gutterBottom>
            {currentCategory.title}
          </Typography>
          <div className="track-transcript">
            {currentTrackTranscript}
          </div>
          <audio controls controlsList="nodownload" ref={audioRef} />
        </Box>
      ): null}
    </Box>
  );

}
